import { Box, Image, Title } from "@mantine/core"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"

import type {
  MultipleChoiceQuestion,
  Question,
  Quiz,
} from "quiz/types/quiz.model"
import { ParcDesPrincesChoices } from "quiz/screen/cast/parc_des_princes/ParcDesPrincesChoices"
import Timer from "screen/parc_des_princes/Timer"

const ParcDesPrincesQuestion = ({
  question,
  quiz,
}: {
  question: Question
  quiz: Omit<Quiz, "id">
}) => {
  const animation = useAnimation()
  const startAnimation = animation.start
  const { question: questionDuration, choices: choicesDuration } =
    quiz.durations

  useEffect(() => {
    startAnimation("logo")

    setTimeout(() => startAnimation("question"), 3000)
    setTimeout(() => startAnimation("choices"), questionDuration * 1000)
    setTimeout(
      () => startAnimation("solution"),
      (questionDuration + choicesDuration) * 1000
    )
  }, [startAnimation, questionDuration, choicesDuration])

  return (
    <Box
      sx={{
        color: quiz.colors.questionText,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        paddingRight: "10rem",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <motion.img
        src={quiz.images.screenAnimatedLogo}
        alt="Screen animated Logo"
        animate={animation}
        variants={{
          logo: {
            opacity: [0, 1, 1, 0],
            scale: [10, 1.05, 1, 0],
            transition: {
              duration: 3,
              times: [0, 0.2, 0.85, 1],
            },
          },
        }}
        style={{
          position: "absolute",
          left: "calc((100vw - 75vw) / 2)",
          maxWidth: "75vw",
        }}
      />
      <motion.div
        initial="initial"
        animate={animation}
        variants={{
          initial: { translateX: "-100vw" },
          choices: {
            translateX: "0vw",
            transition: { type: "spring", bounce: 0 },
          },
        }}
        style={{
          height: "100%",
          flexBasis: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
            background: `url(${quiz.images.screenBackgroundLeft})`,
            backgroundPosition: "-30px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "65%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                flexGrow: 0.5,
                marginTop: "4rem",
                paddingLeft: "4rem",
              }}
            >
              <Image src={quiz.images.questionLogo} alt="Question Logo" />
            </Box>
            <Timer
              color={quiz.colors.questionText}
              initialDelay={quiz.durations.question + 0.5}
              duration={quiz.durations.choices}
            />
          </Box>
        </Box>
      </motion.div>
      <motion.div
        initial="initial"
        animate={animation}
        variants={{
          initial: {
            opacity: 0,
          },
          question: {
            translateY: "0vh",
            opacity: 1,
            transition: { type: "spring", bounce: 0 },
          },
        }}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexBasis: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <motion.div
            variants={{
              question: {
                opacity: [0, 1, 1, 1],
                scale: [10, 2.05, 2, 1],
                translateX: ["-20vw", "-20vw", "-20vw", "0vw"],
                translateY: [
                  "40vh",
                  "40vh",
                  "40vh",
                  question.type === "price_is_right" ? "40vh" : "0vh",
                ],
                transition: {
                  duration: quiz.durations.question - 3,
                  times: [0, 0.05, 0.95, 1],
                },
              },
              solution: {
                translateY: "0vh",
              },
            }}
            style={{
              marginBottom: "4rem",
              maxWidth: "45vw",
            }}
          >
            <Title
              order={1}
              align={"center"}
              sx={{
                fontFamily: "QuizCustomFont",
                fontSize: "80px",
                fontStyle: "oblique 5deg",
                fontWeight: "normal",
              }}
              dangerouslySetInnerHTML={{ __html: question.label }}
            ></Title>
          </motion.div>
        </Box>
        {[undefined, "multiple_choice"].includes(question.type) && (
          <ParcDesPrincesChoices
            question={question as MultipleChoiceQuestion}
            quiz={quiz}
          />
        )}
        {question.type === "price_is_right" && (
          <motion.div
            style={{
              fontSize: "20rem",
              textAlign: "center",
              lineHeight: "80vh",
            }}
            variants={{
              initial: { translateY: "100vh", opacity: 0 },
              solution: {
                transition: { type: "spring", bounce: 0 },
                translateY: 0,
                opacity: 1,
              },
            }}
          >
            {question.solution}
          </motion.div>
        )}
      </motion.div>
    </Box>
  )
}

export { ParcDesPrincesQuestion }
