import { Route, Routes } from "react-router-dom"

import QuizView from "quiz/admin/quiz/QuizView"
import GameView from "quiz/admin/game/GameView"
import { QuizzesListView } from "quiz/admin/quizzes/QuizzesListView"

export function QuizzAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<QuizzesListView />} />
      <Route path="new" element={<QuizView />} />
      <Route path=":quizId">
        <Route path="" element={<QuizView />} />
        <Route path="games/:gameId" element={<GameView />} />
      </Route>
    </Routes>
  )
}
