import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, Group, Loader, Stack, Table, Text } from "@mantine/core"
import { Calendar } from "tabler-icons-react"

import { Th } from "shared/components/table/Th"
import CreateGameModal from "quiz/admin/game/CreateGameModal"
import GameStateLabel from "quiz/admin/game/GameStateLabel"
import type { Game } from "quiz/types/game.model"
import type { Quiz } from "quiz/types/quiz.model"
import { useGetGamesByQuizIdQuery } from "quiz/admin/api/games.endpoints"

type SortedKeys = keyof Pick<Game, "creationTime" | "name">

function sortData(
  data: Game[],
  payload: {
    sortBy: SortedKeys
    reversed: boolean
  }
): Game[] {
  let { sortBy } = payload

  return [...data].sort((a, b) => {
    if (payload.reversed) {
      return b[sortBy].localeCompare(a[sortBy])
    }
    return a[sortBy].localeCompare(b[sortBy])
  })
}

export default function QuizGames({ quiz }: { quiz: Quiz }) {
  const [showCreateGameModal, setShowCreateGameModal] = useState(false)
  const navigate = useNavigate()
  const [sortedData, setSortedData] = useState<Game[]>([])
  const [sortBy, setSortBy] = useState<SortedKeys>("creationTime")
  const [reverseSortDirection, setReverseSortDirection] = useState(false)
  const [needRefresh, setNeedRefresh] = useState<boolean>(true)

  const { data, refetch, isFetching } = useGetGamesByQuizIdQuery({
    quizId: quiz.id,
  })

  const games: Game[] = useMemo(() => (data ? data : []), [data])

  const triggerSortingData = useCallback(
    (field: SortedKeys) => {
      if (games) {
        const reversed = field === sortBy ? !reverseSortDirection : false
        return sortData(games, { sortBy, reversed })
      }
      return []
    },
    [games, reverseSortDirection, sortBy]
  )

  useEffect(() => {
    if (games) {
      setSortedData(triggerSortingData(sortBy))
    }
  }, [games, sortBy, triggerSortingData])

  useEffect(() => {
    if (needRefresh) {
      refetch()
      setNeedRefresh(false)
    }
  }, [needRefresh, refetch])

  return (
    <>
      <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
        <CreateGameModal
          quizSnapshot={quiz}
          opened={showCreateGameModal}
          onClose={() => setShowCreateGameModal(false)}
        />
        {isFetching ? (
          <Stack align={"center"}>
            <Loader />
          </Stack>
        ) : null}
        {!isFetching && games.length > 0 ? (
          <Stack>
            <Table highlightOnHover>
              <thead>
                <tr>
                  <Th
                    reversed={reverseSortDirection}
                    sorted={sortBy === "name"}
                    onClick={() => {
                      if (sortBy === "name") {
                        setReverseSortDirection(!reverseSortDirection)
                      } else {
                        setSortBy("name")
                      }
                    }}
                  >
                    Nom
                  </Th>
                  <Th
                    reversed={reverseSortDirection}
                    sorted={sortBy === "creationTime"}
                    onClick={() => {
                      if (sortBy === "creationTime") {
                        setReverseSortDirection(!reverseSortDirection)
                      } else {
                        setSortBy("creationTime")
                      }
                    }}
                  >
                    Date de création
                  </Th>
                </tr>
              </thead>
              <tbody>
                {sortedData.length > 0 &&
                  sortedData.map((game) => (
                    <tr
                      key={game.id}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`games/${game.id}`, {
                          state: { game },
                        })
                      }
                    >
                      <td>
                        <Text variant="link">
                          {game.name || `Partie #${game.id.split("-")[0]}`}
                        </Text>
                      </td>
                      <td>
                        <Group>
                          <Calendar />
                          {new Date(game.creationTime).toLocaleString("fr-FR", {
                            timeZone: "UTC",
                          })}
                        </Group>
                      </td>
                      <td>
                        <GameStateLabel game={game!} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Stack>
        ) : null}
        {!isFetching && games.length === 0 ? (
          <Stack align={"center"}>
            <Box>Aucune partie trouvée</Box>
          </Stack>
        ) : null}
      </Box>
      <br />
      <Stack align={"center"}>
        <Button
          className="bg-button-admin"
          onClick={() => setShowCreateGameModal(true)}
        >
          Créer une partie
        </Button>
      </Stack>
    </>
  )
}
