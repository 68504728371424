import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  Center,
  Container,
  Button,
  Image,
  NumberInput,
  NumberInputProps,
  Stack,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useViewportSize } from "@mantine/hooks"
import { Carousel } from "@mantine/carousel"
import { useSpring } from "framer-motion"

import { useGetEventQuery } from "event/api/event.api"
import type { Event, WidgetIds } from "event/types/event.model"
import { useSavedEvent } from "event/hooks/useSavedEvent"
import QueryWrapper from "shared/components/QueryWrapper"
import { PSGHeader } from "../components/PSGHeader"

import { config } from "config"
import {
  IUserProfile,
  WidgetKind,
  claimPredictionWidgetRewards,
  createWidgetInteraction,
  init,
  getLeaderboardProfileRank,
  getWidget,
  getWidgetInteractions,
  updateUserProfile,
} from "@livelike/javascript"
import type { SavedEvent } from "event/types/types"
import { useAddPronosticMutation } from "pronostic/api/pronostic.endpoints"
import { Pronostic } from "pronostic/types/pronostic.model"
import "event/play/common/PronosticView.css"

interface CustomNumberInputProps extends NumberInputProps {
  name: string
}

function CustomNumberInput({
  name,
  ...props
}: Partial<CustomNumberInputProps>) {
  const numberInputStyle = {
    margin: "5px",
  }
  return (
    <NumberInput
      hideControls
      min={0}
      maxLength={2}
      style={{ ...numberInputStyle }}
      styles={{
        input: {
          fontSize: "25px",
          width: "35px",
          color: "white",
          padding: "0",
          textAlign: "center",
          borderRadius: "0",
          backgroundColor: "transparent",
        },
      }}
      {...props}
    />
  )
}

const WIDGET_KINDS: Record<WidgetIds, WidgetKind> = {
  match_end_score: WidgetKind.IMAGE_NUMBER_PREDICTION,
  goals_stopped: WidgetKind.IMAGE_NUMBER_PREDICTION,
  best_scorer: WidgetKind.IMAGE_PREDICTION,
  last_scorer: WidgetKind.IMAGE_PREDICTION,
}

type UsedWidgets = keyof typeof WIDGET_KINDS

const HAS_RESULT: Record<UsedWidgets, (results: Event["results"]) => boolean> =
  {
    match_end_score: (results) =>
      results.match_end_score.own !== null &&
      results.match_end_score.opponent !== null,
    goals_stopped: (results) =>
      results.goals_stopped[0] !== null && results.goals_stopped[1] !== null,
    best_scorer: (results) => results.best_scorer !== null,
    last_scorer: (results) => results.last_scorer !== null,
  }

const maxRealScore = 8
const maxScore = 10

const needleAngle = (score: number) => {
  const minAngle = -212
  const maxAngle = 62
  const range = maxAngle - minAngle
  return minAngle + (score / maxScore) * range
}

function PronosticView() {
  const navigate = useNavigate()

  const [createPronostic] = useAddPronosticMutation()

  const styles = {
    desktopBackground: {
      backgroundImage: "url(/psg/background_desktop.png)",
    },
    mobileBackground: {
      backgroundImage: "url(/psg/background_mobile.png)",
      backgroundAttachment: "fixed",
    },
  }

  const settings_carousel = {
    slideSize: "20%",
    slidesToScroll: 1,
    withControls: true,
    controlsOffset: 0,
    controlSize: 14,
    withIndicators: false,
    withKeyboardEvents: true,
  }

  const { width } = useViewportSize()
  const isMobile = width <= 768
  const currentStyle = isMobile
    ? styles.mobileBackground
    : styles.desktopBackground

  const { eventId } = useParams<{ eventId: string }>()
  const getEventQuery = useGetEventQuery(eventId as string)
  const [savedEvent, setSavedEvent] = useSavedEvent(eventId!)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  function handleValidation(errors: any) {
    if (Object.values(errors).some((error) => error != null)) {
      setErrorMessage(
        "Vous devez saisir tous les pronostics pour pouvoir les valider."
      )
    } else {
      setErrorMessage(null)
    }
  }

  function calculateTimeout(event: Event | undefined) {
    if (!event)
      return { days: 0, hours: 0, minutes: 0, seconds: 0, timeout_ms: 0 }

    const timeout_ms = Math.max(
      0,
      new Date(event.kickoff_date).valueOf() - new Date().valueOf()
    )
    const days = Math.floor(timeout_ms / 1000 / 60 / 60 / 24)
    let remainder = timeout_ms % (1000 * 60 * 60 * 24)
    const hours = Math.floor(remainder / 1000 / 60 / 60)
    remainder %= 1000 * 60 * 60
    const minutes = Math.floor(remainder / 1000 / 60)
    remainder %= 1000 * 60
    const seconds = Math.floor(remainder / 1000)
    return { days, hours, minutes, seconds, timeout_ms }
  }
  const maybeEvent = getEventQuery.data
  const initialTimeout = calculateTimeout(maybeEvent)
  const [timeout, setTimeoutState] = useState(initialTimeout)
  const [inPast, setInPast] = useState(initialTimeout.timeout_ms <= 0)
  const [activeSlide1, setActiveSlide1] = React.useState(0)
  const [activeSlide2, setActiveSlide2] = React.useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeout = calculateTimeout(maybeEvent)
      setTimeoutState(timeout)

      if (timeout.timeout_ms <= 0) {
        clearInterval(intervalId)
        setInPast(true)
      } else {
        setInPast(false)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [maybeEvent])

  const [profile, setProfile] = useState<IUserProfile | null>(null)

  const fetchLiveLikeProfile = useCallback(async () => {
    const profile = await init({
      clientId: config.livelike.clientId,
      accessToken: savedEvent?.accessToken,
    })
    setProfile(profile)
  }, [savedEvent])

  useEffect(() => {
    if (!savedEvent) {
      navigate("./..")
    }
    fetchLiveLikeProfile()
  }, [fetchLiveLikeProfile, navigate, savedEvent])

  const updateLiveLikeUserProfile = useCallback(async () => {
    if (profile && savedEvent) {
      return await updateUserProfile({
        accessToken: profile.access_token,
        options: {
          custom_data: JSON.stringify({
            playerId: savedEvent.playerId,
            partitionId: savedEvent.partitionId,
          }),
        },
      })
    }
  }, [profile, savedEvent])

  useEffect(() => {
    if (profile && savedEvent && !savedEvent.accessToken) {
      const { playerId, partitionId, playerInfo } = savedEvent
      setSavedEvent(
        {
          playerId,
          partitionId,
          accessToken: profile.access_token,
        },
        playerInfo
      )
    }
    if (profile && savedEvent && !profile.custom_data) {
      updateLiveLikeUserProfile()
    }
  }, [profile, savedEvent, setSavedEvent, updateLiveLikeUserProfile])

  const [showResults, setShowResults] = useState(false)

  useEffect(() => {
    if (!maybeEvent || !profile) return

    claimInteractions()

    async function claimInteractions() {
      const program = maybeEvent!.livelike_program
      const programId = program.program_id!
      const widget_ids = program.widget_ids
      const results = maybeEvent!.results

      let update_needed = false

      await Promise.all(
        Object.keys(WIDGET_KINDS).map(async (_name) => {
          const name = _name as UsedWidgets

          if (!HAS_RESULT[name](results)) return

          const widgetId = widget_ids[name]!
          const widgetKind = WIDGET_KINDS[name]

          const widget = await getWidget({
            widgetId,
            widgetKind,
          })
          const { follow_ups } = widget

          if (!follow_ups || follow_ups.length === 0) return

          const interactions = await getWidgetInteractions({
            programId,
            widgetId,
            widgetKind,
          })

          if (interactions.length === 0) return

          const follow_up = follow_ups[0]

          try {
            await claimPredictionWidgetRewards({
              widgetId: follow_up.id,
              widgetKind: follow_up.kind,
            })
          } catch (error: any) {
            if (error.status !== 409) throw error
          }

          update_needed = true
        })
      )

      if (update_needed) setShowResults(true)
    }
  }, [maybeEvent, profile])

  const [score, setScore] = useState<number>(0)
  const [scoreFloat, setScoreFloat] = useState<number>(0)
  const displayScore = useSpring(0, { duration: 2000, bounce: 0 })

  useEffect(() => {
    return displayScore.onChange((value) => {
      setScore(Math.round(value))
      setScoreFloat(value)
    })
  })

  useEffect(() => {
    if (!maybeEvent || !profile) return

    const event = maybeEvent!
    loadScore()

    async function loadScore() {
      const { score } = await getLeaderboardProfileRank({
        leaderboardId: event.livelike_program.leaderboard_id!,
        profileId: profile!.id,
      })
      if (showResults) displayScore.set((score * maxScore) / maxRealScore)
    }
  }, [showResults, maybeEvent, profile, displayScore])

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (!maybeEvent || !profile) return

    loadInteractions()

    async function loadInteractions() {
      const program = maybeEvent!.livelike_program
      const widget_ids = program.widget_ids
      const interactions = await getWidgetInteractions({
        programId: program.program_id!,
        widgetId: widget_ids.match_end_score!,
        widgetKind: WIDGET_KINDS.match_end_score,
      })

      if (interactions.length) setDisabled(true)
    }
  }, [maybeEvent, profile, setDisabled])

  const form = useForm({
    initialValues: {
      match_end_score: { own: null, opponent: null },
      best_scorer: 0,
      last_scorer: 0,
      goals_stopped: [null, null],
    },
    validate: {
      match_end_score: {
        own: (value) => {
          if (value == null) return ""
        },
        opponent: (value) => {
          if (value == null) return ""
        },
      },
      goals_stopped: (value) => {
        if (value[0] == null || value[1] == null) return ""
      },
    },
  })

  useEffect(() => {
    handleValidation(form.errors)
  }, [form.errors])

  const saveToPronosticRDS = useCallback(
    ({
      event,
      savedEvent,
      values,
    }: {
      event: Event
      savedEvent: SavedEvent
      values: any
    }) => {
      const newPronostic: Partial<Pronostic> = {
        eventId: event.id,
        eventTitle: `${event.organization} VS ${event.opponent.name}`,
        participantId: savedEvent.playerId,
        participantEmail: savedEvent.playerInfo?.email,
        participantPhoneNumber: savedEvent.playerInfo?.phoneNumber,
      }

      const payload = [
        createPronostic({
          ...newPronostic,
          pronosticLabel: "MainTeamGoals",
          pronosticValue: values.match_end_score.own.toString(),
        }),
        createPronostic({
          ...newPronostic,
          pronosticLabel: "OpponentGoals",
          pronosticValue: values.match_end_score.opponent.toString(),
        }),
        createPronostic({
          ...newPronostic,
          pronosticLabel: "BestScorer",
          pronosticValue: values.best_scorer,
        }),
        createPronostic({
          ...newPronostic,
          pronosticLabel: "LastScorer",
          pronosticValue: values.last_scorer,
        }),
        createPronostic({
          ...newPronostic,
          pronosticLabel: "FirstGoalKeeperStops",
          pronosticValue: values.goals_stopped[0].toString(),
        }),
        createPronostic({
          ...newPronostic,
          pronosticLabel: "SecondGoalKeeperStops",
          pronosticValue: values.goals_stopped[1].toString(),
        }),
      ]
      return Promise.all(payload)
    },
    [createPronostic]
  )

  const onSubmit = useCallback(
    async (values: any) => {
      if (!maybeEvent) return
      saveToPronosticRDS({ event: maybeEvent, savedEvent, values })
      const program = maybeEvent.livelike_program
      const widget_ids = program.widget_ids

      const WidgetInfo = {
        match_end_score: {
          id: widget_ids.match_end_score!,
          kind: WidgetKind.IMAGE_NUMBER_PREDICTION,
          get_interaction_item: (widget: any) => {
            const [psg, opponent] = widget.options
            return {
              options: [
                {
                  optionId: psg.id,
                  number: values.match_end_score.own,
                },
                {
                  optionId: opponent.id,
                  number: values.match_end_score.opponent,
                },
              ],
            }
          },
        },
        best_scorer: {
          id: widget_ids.best_scorer!,
          kind: WidgetKind.IMAGE_PREDICTION,
          get_interaction_item: (widget: any) =>
            widget.options[values.best_scorer],
        },
        last_scorer: {
          id: widget_ids.last_scorer!,
          kind: WidgetKind.IMAGE_PREDICTION,
          get_interaction_item: (widget: any) =>
            widget.options[values.last_scorer],
        },
        goals_stopped: {
          id: widget_ids.goals_stopped!,
          kind: WidgetKind.IMAGE_NUMBER_PREDICTION,
          get_interaction_item: (widget: any) => {
            const [goalkeeper_1, goalkeeper_2] = widget.options
            return {
              options: [
                {
                  optionId: goalkeeper_1.id,
                  number: values.goals_stopped[0],
                },
                {
                  optionId: goalkeeper_2.id,
                  number: values.goals_stopped[1],
                },
              ],
            }
          },
        },
      }

      let interactions = []

      for (const info of Object.values(WidgetInfo)) {
        interactions.push(interact(info))
      }

      await Promise.all(interactions)
      setDisabled(true)

      async function interact(info: any) {
        const widget = await getWidget({
          widgetId: info.id,
          widgetKind: info.kind,
        })
        const interactionItem = info.get_interaction_item(widget)
        await createWidgetInteraction({
          widgetId: info.id,
          widgetKind: info.kind,
          interactionItem,
        })
      }
    },
    [maybeEvent, setDisabled, saveToPronosticRDS, savedEvent]
  )

  return (
    <QueryWrapper query={getEventQuery}>
      {(event) => (
        <Container
          size="xs"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "none",
            height: "100dvh",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "start",
            backgroundSize: "cover",
            backgroundPosition: "center",
            paddingBottom: "20px",
            ...currentStyle,
          }}
        >
          <PSGHeader />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <div
              style={{
                display: disabled || inPast ? "none" : "flex",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1.3",
                fontSize: "16px",
                fontFamily: "Team-A-Bold",
              }}
            >
              <p>FIN DANS</p>
              <div
                style={{
                  color: "#e30613",
                  border: "1px solid #e30613",
                  marginLeft: "10px",
                  padding: "0 10px",
                  backgroundColor: "white",
                }}
              >
                {timeout.days}J {timeout.hours}H {timeout.minutes}MIN{" "}
                {timeout.seconds}SEC
              </div>
            </div>
          </div>
          {showResults ? (
            <Stack style={{ flex: "1 1 0" }}>
              <span
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontFamily: "Team-A-Bold",
                  marginTop: "1.5rem",
                  textTransform: "uppercase",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Ton score est de :<br />
                {score}/{maxScore} points
                <br />
              </span>
              <div
                style={{
                  position: "relative",
                }}
              >
                <Image src={"/gauge.png"} alt="gauge PNG harcoded" />
                <Image
                  src={"/needle.png"}
                  alt="needle PNG harcoded"
                  style={{
                    position: "absolute",
                    width: "40%",
                    top: "50%",
                    left: "50%",
                    transform:
                      "translateX(-21.5%) translateY(-24%) rotate(" +
                      needleAngle(scoreFloat) +
                      "deg)",
                    transformOrigin: "21.5% 68%",
                  }}
                />
              </div>
            </Stack>
          ) : inPast ? (
            <Stack style={{ flex: "1 1 0" }}>
              <Center>
                <Image
                  src={"/psg/waiting_logo.png"}
                  alt="Waiting Logo harcoded"
                  width={65}
                  sx={{
                    paddingTop: "3rem",
                  }}
                />
              </Center>
              <span
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontFamily: "Team-A-Bold",
                  textTransform: "uppercase",
                  flex: "calc(2/3) 1 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Il n'est désormais plus possible de
                <br />
                pronostiquer pour la rencontre.
              </span>
            </Stack>
          ) : disabled ? (
            <Stack style={{ flex: "1 1 0" }}>
              <Center>
                <Image
                  src={"/psg/waiting_logo.png"}
                  alt="Waiting Logo harcoded"
                  width={65}
                  sx={{
                    paddingTop: "3rem",
                  }}
                />
              </Center>
              <p
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontFamily: "Team-A-Bold",
                  textTransform: "uppercase",
                  flex: "calc(2/3) 1 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Merci d'avoir participé !<br />
                Rendez-vous après la rencontre pour voir vos résultats !
              </p>
            </Stack>
          ) : (
            <section
              className="page1"
              style={{
                overflowY: "scroll",
                maxWidth: "480px",
              }}
            >
              <form onSubmit={form.onSubmit(onSubmit)}>
                <div>
                  {/* SCORE MI-TEMPS */}
                  <div
                    style={{
                      display: "flex",
                      color: "white",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      paddingBottom: "20px",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontSize: "16px",
                          margin: "0",
                          textAlign: "center",
                          fontFamily: "Team-A-Bold",
                          paddingBottom: "8px",
                        }}
                      >
                        SCORE FINAL
                      </p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src="/psg/event_logo.png"
                          alt="PSG Logo"
                          style={{ width: "30px" }}
                        />
                        <CustomNumberInput
                          {...form.getInputProps("match_end_score.own")}
                        />
                        <CustomNumberInput
                          {...form.getInputProps("match_end_score.opponent")}
                        />
                        <img
                          src={event.opponent.logo}
                          alt="Opponent Logo"
                          style={{ width: "30px" }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* SAVES AND TOP SCORER GOALS */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "white",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "100%",
                      paddingBottom: "20px",
                      fontSize: "16px",
                      fontFamily: "Team-A-Bold",
                    }}
                  >
                    <div
                      style={{
                        height: "3px",
                        width: "23%",
                        backgroundColor: "white",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "70%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>COMBIEN D'ARRÊTS ?</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <CustomNumberInput
                            {...form.getInputProps("goals_stopped.0")}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="/psg/goal_1.png"
                              alt="Goalkeeper"
                              style={{ width: "50px" }}
                            />
                            <p style={{ width: "90%" }}>ANDREAS PALICKA</p>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src="/psg/goal_2.png"
                              alt="Goalkeeper"
                              style={{ width: "50px" }}
                            />
                            <p style={{ width: "90%" }}>JANNICK GREEN</p>
                          </div>
                          <CustomNumberInput
                            {...form.getInputProps("goals_stopped.1")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{}} className="page2">
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "3px",
                        width: "23%",
                        backgroundColor: "white",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    ></div>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Team-A-Bold",
                      }}
                    >
                      QUI SERA LE MEILLEUR BUTEUR ?
                    </p>
                    <Carousel
                      {...settings_carousel}
                      style={{
                        width: "100%",
                      }}
                      initialSlide={activeSlide2}
                      onSlideChange={(index) => {
                        form.setFieldValue("best_scorer", index)
                        setActiveSlide2(index)
                      }}
                    >
                      {event.workforce_snapshot?.players.map(
                        (player, index) => (
                          <Carousel.Slide>
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                padding: "0",
                                paddingTop: "2%",
                                border:
                                  index === form.values.best_scorer
                                    ? "2px dashed white"
                                    : "none",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                form.setFieldValue("best_scorer", index)
                                setActiveSlide2(index)
                                e.stopPropagation()
                              }}
                            >
                              <img
                                style={{ width: "50%" }}
                                src={player.logo}
                                alt={player.name}
                              />
                              <p style={{ width: "90%" }}>{player.name}</p>
                            </div>
                          </Carousel.Slide>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "95%",
                      color: "white",
                      paddingBottom: "20px",
                      marginTop: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          height: "3px",
                          width: "23%",
                          backgroundColor: "white",
                          marginTop: "20px",
                          marginBottom: "10px",
                        }}
                      ></div>
                      <p
                        style={{
                          fontSize: "16px",
                          fontFamily: "Team-A-Bold",
                        }}
                      >
                        LE DERNIER BUTEUR?
                      </p>
                      <Carousel
                        {...settings_carousel}
                        style={{
                          width: "100%",
                        }}
                        initialSlide={activeSlide1}
                        onSlideChange={(index) => {
                          form.setFieldValue("last_scorer", index)
                          setActiveSlide1(index)
                        }}
                      >
                        {event.workforce_snapshot?.players.map(
                          (player, index) => (
                            <Carousel.Slide>
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  padding: "0",
                                  paddingTop: "2%",
                                  border:
                                    index === form.values.last_scorer
                                      ? "2px dashed white"
                                      : "none",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  form.setFieldValue("last_scorer", index)
                                  setActiveSlide1(index)
                                  e.stopPropagation()
                                }}
                              >
                                <img
                                  style={{ width: "50%" }}
                                  src={player.logo}
                                  alt={player.name}
                                />
                                <p style={{ width: "90%" }}>{player.name}</p>
                              </div>
                            </Carousel.Slide>
                          )
                        )}
                      </Carousel>
                    </div>
                  </div>
                  {errorMessage && (
                    <div style={{ color: "#e30613", padding: "20px" }}>
                      {errorMessage}
                    </div>
                  )}
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#e30613",
                      borderColor: "white",
                      color: "white",
                      marginTop: "10px",
                    }}
                  >
                    VALIDER
                  </Button>
                </div>
              </form>
            </section>
          )}
        </Container>
      )}
    </QueryWrapper>
  )
}

export { PronosticView }
