import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "shared/utils/api/apiHelpers"
import type { Message } from "wall/types/message.model"

const wallApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ["Messages"] })
  .injectEndpoints({
    endpoints: (build) => ({
      ...makeCrudEndpoints<"Message", Message>("Message", "messages")(build),
      updateMessage: build.mutation<
        Message,
        Pick<Message, "id" | "wallId"> & Partial<Message>
      >({
        query: ({ id, ...body }) => ({
          method: "POST",
          url: `messages/${id}`,
          body,
        }),
        async onQueryStarted(
          { id, wallId, ...patch },
          { dispatch, queryFulfilled }
        ) {
          dispatch(
            wallApi.util.updateQueryData(
              "listMessages",
              { wallId },
              (draft) => {
                Object.assign(
                  draft.find((message) => message.id === id)!,
                  patch
                )
              }
            )
          )
          try {
            await queryFulfilled
          } catch {
            // dispatch(wallApi.util.invalidateTags(["Messages"]));
          }
        },
      }),
      deleteMessage: build.mutation<Message, Message>({
        query: ({ id, ...body }) => ({
          method: "DELETE",
          url: `messages/${id}`,
          body,
        }),
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            wallApi.util.updateQueryData("listMessages", { id }, (draft) => {
              Object.assign(draft, patch)
            })
          )
          try {
            await queryFulfilled
          } catch {
            patchResult.undo()
          }
        },
      }),
    }),
  })

export const {
  useGetMessageQuery,
  useListMessagesQuery,
  useAddMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation,
} = wallApi
