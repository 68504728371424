import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { merge } from "lodash"
import {
  ActionIcon,
  Button,
  Group,
  Radio,
  Select,
  Stack,
  Switch,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core"
import { CirclePlus, Eye, Trash } from "tabler-icons-react"

import {
  QUIZ_GAME_DEFAULT_ENDING_MOBILE_MSG,
  QUIZ_GAME_DEFAULT_ENDING_SCREEN_MSG,
  QUIZ_GAME_DEFAULT_WINNER_PRIVATE_MSG,
  QUIZ_ADMIN_WRONG_QUESTION_FORMAT,
  QUIZ_ADMIN_MISSING_WINNER_TEMPLATE,
} from "_constants"
import {
  ORGANIZATIONS,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
} from "_roles"
import type { Nullable } from "types"
import type {
  PriceIsRightQuestion,
  Question,
  Quiz,
} from "quiz/types/quiz.model"
import { quizMode, quizScreenType } from "quiz/types/quiz.model"

import Collapsable from "common/admin/ui/Collapsable"
import { AccessRights } from "common/admin/auth/AccessRights"
import { useOrganization } from "common/admin/auth/hooks"
import QueryWrapper from "shared/components/QueryWrapper"
import QuestionForm from "quiz/admin/quiz/question/QuestionForm"
import QuizGames from "quiz/admin/quiz/QuizGames"
import QuestionPreviewModal from "quiz/admin/quiz/question/QuestionPreviewModal"
import QuizDurationsForm from "quiz/admin/quiz/QuizDurationsForm"
import QuizImagesForm from "quiz/admin/quiz/QuizImagesForm"
import QuizColorsForm from "quiz/admin/quiz/QuizColorsForm"
import { QuizBreadcrumbs } from "quiz/admin/components/QuizBreadcrumbs"
import { useGetQuizQuery } from "quiz/admin/api/quizzes.endpoints"
import {
  defaultQuizzes,
  QuizFormProvider,
  useQuizForm,
} from "quiz/admin/quiz/quizForm"
import QuizToolbar from "quiz/admin/quiz/QuizToolbar"

export default function QuizView() {
  const { quizId } = useParams<{ quizId: string }>()
  const isCreateMode: boolean = !quizId
  const [organization] = useOrganization()

  const form = useQuizForm({
    initialValues: {
      mode: "multiple_choice" as quizMode,
      ...defaultQuizzes.bandeau_horizontal,
      endingMobileMsg: QUIZ_GAME_DEFAULT_ENDING_MOBILE_MSG,
      endingMsg: QUIZ_GAME_DEFAULT_ENDING_SCREEN_MSG,
      winnerMsg: QUIZ_GAME_DEFAULT_WINNER_PRIVATE_MSG,
    },
    validate: {
      questions: {
        solution: (value) => {
          if (form.values.mode !== "price_is_right") return null

          return /^\d+$/.test(value.toString())
            ? null
            : QUIZ_ADMIN_WRONG_QUESTION_FORMAT
        },
      },
      endingMsg: (value) => {
        const isWinnerDefined = value.search(/#WINNER#/)
        return isWinnerDefined < 0 ? QUIZ_ADMIN_MISSING_WINNER_TEMPLATE : false
      },
    },
  })
  const setFormValues = form.setValues

  const getQuizQuery = useGetQuizQuery(quizId ?? skipToken)
  const quizQueryData = getQuizQuery.data

  const [showQuestionPreview, setShowQuestionPreview] =
    useState<Nullable<Question>>(null)

  const updateQuizForm = useCallback(
    (screenType: quizScreenType) => {
      const {
        name: _,
        questions: __,
        ...defaultQuiz
      } = defaultQuizzes[screenType]

      setFormValues((prev) =>
        merge({}, prev, defaultQuiz, quizQueryData, { screenType })
      )
    },
    [quizQueryData, setFormValues]
  )

  useLayoutEffect(() => {
    if (quizQueryData) {
      const screenType: quizScreenType =
        quizQueryData.screenType ?? ("bandeau_horizontal" as quizScreenType)
      updateQuizForm(screenType)
    }
  }, [quizQueryData, updateQuizForm])

  const location = useLocation()

  useLayoutEffect(() => {
    const state = location.state as { from?: Quiz } | undefined
    if (state?.from) {
      setFormValues(state.from)
      window.history.replaceState(null, "")
    }
  }, [location, setFormValues])

  useEffect(() => {
    if (organization && organization !== "Admin") {
      form.setValues({ organization })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  return (
    <QueryWrapper query={getQuizQuery}>
      {(quiz) => (
        <QuizFormProvider form={form}>
          <QuizBreadcrumbs quiz={quiz ?? "new"} />

          <Title order={3} align={"center"} m={"lg"}>
            {isCreateMode
              ? "Nouveau quiz"
              : quiz?.name || `Quiz #${quiz?.id?.split("-")[0]}`}
          </Title>

          <QuizToolbar form={form} />
          <Stack>
            {!isCreateMode ? (
              <Collapsable title="Historique des parties">
                <QuizGames quiz={quiz!} />
              </Collapsable>
            ) : null}

            <Collapsable title="Paramètres">
              <Stack>
                <AccessRights
                  authorizedRoles={
                    QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
                  }
                  authorizedOrganizations={
                    QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
                  }
                >
                  <Select
                    sx={{ flex: 1 }}
                    label="Organisme"
                    required
                    data={ORGANIZATIONS}
                    {...form.getInputProps("organization")}
                  />
                </AccessRights>
                <TextInput
                  autoFocus={(location.state as any)?.from}
                  required
                  sx={{ flex: 1 }}
                  label={"Nom"}
                  {...form.getInputProps("name")}
                />
                <Radio.Group
                  value={form.values.screenType}
                  onChange={(value) => {
                    const type = value as quizScreenType
                    form.setFieldValue("type", type)
                    updateQuizForm(type)
                  }}
                >
                  {Object.entries(quizScreenType).map(
                    ([name, screenType], idx) => (
                      <Radio key={idx} value={screenType} label={name} />
                    )
                  )}
                </Radio.Group>
                <Radio.Group
                  value={form.values.mode}
                  onChange={(value) => {
                    form.setFieldValue("mode", value as quizMode)

                    const questions =
                      value === "multiple_choice"
                        ? []
                        : [
                            {
                              type: "price_is_right",
                              label: "",
                              solution: null!,
                            },
                          ]
                    form.setFieldValue(
                      "questions",
                      questions as PriceIsRightQuestion[]
                    )
                  }}
                >
                  {Object.entries(quizMode).map(([name, mode], idx) => (
                    <Radio key={idx} value={mode} label={name} />
                  ))}
                </Radio.Group>

                <Switch
                  checked={form.values.hasSeating}
                  label="Informations de place"
                  onLabel="Oui"
                  offLabel="Non"
                  onChange={(event) => {
                    const value = event.currentTarget.checked
                    form.setFieldValue("hasSeating", value)
                  }}
                  {...form.getInputProps("hasSeating")}
                />

                <Textarea
                  key="ending-mobile-msg"
                  label="Message de fin de jeu sur mobile"
                  name="endingMobileMsg"
                  placeholder={QUIZ_GAME_DEFAULT_ENDING_MOBILE_MSG}
                  {...form.getInputProps("endingMobileMsg")}
                />
                <Textarea
                  key="winner-msg"
                  label="Message privatif pour le vainqueur"
                  name="winnerMsg"
                  placeholder={QUIZ_GAME_DEFAULT_WINNER_PRIVATE_MSG}
                  {...form.getInputProps("winnerMsg")}
                />
                <Textarea
                  key="ending-msg"
                  label="Message de fin de jeu sur écran"
                  name="endingMsg"
                  placeholder={
                    form.errors ? "" : QUIZ_GAME_DEFAULT_ENDING_SCREEN_MSG
                  }
                  {...form.getInputProps("endingMsg")}
                />
              </Stack>
            </Collapsable>

            <Collapsable title="Timing">
              <QuizDurationsForm form={form} />
            </Collapsable>

            <Collapsable title={"Couleurs"}>
              <QuizColorsForm form={form} />
            </Collapsable>

            <Collapsable title={"Images"}>
              <QuizImagesForm form={form} />
            </Collapsable>

            {form.values.questions?.map((question, idx) => (
              <Collapsable
                title={`Question #${idx + 1}`}
                key={idx}
                actions={
                  <Group>
                    <ActionIcon
                      variant="subtle"
                      onClick={() => setShowQuestionPreview(question)}
                    >
                      <Eye size={16} />
                    </ActionIcon>
                    {(!form.values.mode ||
                      form.values.mode === "multiple_choice") && (
                      <ActionIcon
                        color="red"
                        variant="subtle"
                        onClick={() => form.removeListItem("questions", idx)}
                      >
                        <Trash size={16} />
                      </ActionIcon>
                    )}
                  </Group>
                }
              >
                <QuestionForm form={form} questionIdx={idx} />
              </Collapsable>
            ))}

            <QuestionPreviewModal
              opened={showQuestionPreview != null}
              onClose={() => setShowQuestionPreview(null)}
              question={showQuestionPreview!}
              quiz={form.values!}
            />

            {(!form.values.mode || form.values.mode === "multiple_choice") && (
              <Group position={"center"}>
                <Button
                  variant={"subtle"}
                  leftIcon={<CirclePlus />}
                  onClick={() =>
                    form.insertListItem("questions", {
                      type: "multiple_choice",
                      label: "",
                      choices: [],
                      solutionIdx: 0,
                    })
                  }
                >
                  Ajouter une question
                </Button>
              </Group>
            )}
          </Stack>
        </QuizFormProvider>
      )}
    </QueryWrapper>
  )
}
