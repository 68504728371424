import { useMemo } from "react"
import { useListMessagesQuery } from "wall/api/messages.endpoints"
import QueryWrapper from "shared/components/QueryWrapper"
import { useWall } from "wall/hooks/useWall"
import ImagesSlideshow from "./ImagesSlideshow"
import MessagesSlideshow from "./MessagesSlideshow"
import { wallTemplate } from "wall/types/wall.model"

function WallCastView({ mode }: { mode: "messages" | "images" }) {
  const wall = useWall()!
  const listMessagesQuery = useListMessagesQuery({ wallId: wall.id })

  const filteredMessages = useMemo(
    () => listMessagesQuery.data?.filter((message) => message.approved),
    [listMessagesQuery]
  )

  return (
    <QueryWrapper query={listMessagesQuery}>
      {() =>
        (filteredMessages?.length &&
          (mode === "images" ? (
            <ImagesSlideshow messages={filteredMessages} />
          ) : (
            <MessagesSlideshow
              messages={filteredMessages}
              template={wall.template || ("bandeau_horizontal" as wallTemplate)}
            />
          ))) ||
        ""
      }
    </QueryWrapper>
  )
}

export default WallCastView
