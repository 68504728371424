import { Box, Title } from "@mantine/core"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"

import type {
  MultipleChoiceQuestion,
  Question,
  Quiz,
} from "quiz/types/quiz.model"
import { CourbertinChoices } from "quiz/screen/cast/coubertin/CourbertinChoices"
import Timer from "screen/coubertin/Timer"

const CoubertinQuestion = ({
  question,
  quiz,
}: {
  question: Question
  quiz: Omit<Quiz, "id">
}) => {
  const animation = useAnimation()
  const startAnimation = animation.start
  const { question: questionDuration, choices: choicesDuration } =
    quiz.durations

  useEffect(() => {
    startAnimation("question")

    setTimeout(() => startAnimation("choices"), questionDuration * 1000)
    setTimeout(
      () => startAnimation("solution"),
      (questionDuration + choicesDuration) * 1000
    )
  }, [startAnimation, questionDuration, choicesDuration])

  return (
    <Box
      sx={{
        color: quiz.colors.questionText,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 125,
        boxSizing: "border-box",
      }}
    >
      <motion.div
        initial="initial"
        animate={animation}
        style={{ height: "100%", width: "calc(100vw - 25px)" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "100%",
          }}
        >
          <motion.div
            variants={{
              initial: {
                translateX: "50%",
                translateY: "100vh",
                opacity: 0,
              },
              question: {
                translateY: "0vh",
                opacity: 1,
                transition: { type: "spring", bounce: 0 },
              },
              choices: {
                translateX: "0%",
                transition: { type: "spring", bounce: 0 },
              },
              solution: {
                translateX: "0%",
                transition: { type: "spring", bounce: 0 },
              },
            }}
            style={{ width: "30%", textAlign: "center" }}
          >
            <Title
              order={1}
              sx={{ fontSize: "36px", fontFamily: "QuizCustomFont" }}
              align={"center"}
              dangerouslySetInnerHTML={{ __html: question.label }}
            ></Title>
          </motion.div>
          {[undefined, "multiple_choice"].includes(question.type) && (
            <CourbertinChoices
              question={question as MultipleChoiceQuestion}
              quiz={quiz}
            />
          )}
          {question.type === "price_is_right" && (
            <motion.div
              style={{ fontSize: "42px", textAlign: "center" }}
              variants={{
                initial: { translateY: "100vh", opacity: 0 },
                solution: {
                  transition: { type: "spring", bounce: 0 },
                  translateY: 0,
                  opacity: 1,
                },
              }}
            >
              {question.solution}
            </motion.div>
          )}
          <Timer
            color={quiz.colors.questionText}
            initialDelay={quiz.durations.question + 0.5}
            duration={quiz.durations.choices}
          />
        </Box>
      </motion.div>
    </Box>
  )
}

export { CoubertinQuestion }
