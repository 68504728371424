import { useEffect, useMemo, useState } from "react"
import { Box, Modal } from "@mantine/core"

import { config } from "config"
import type { Nullable } from "types"
import type { Question, Quiz } from "quiz/types/quiz.model"

import { CoubertinQuestion } from "quiz/screen/cast/coubertin/CoubertinQuestion"
import { ParcDesPrincesQuestion } from "quiz/screen/cast/parc_des_princes/ParcDesPrincesQuestion"

const previewScale = 1.0

export default function QuestionPreviewModal({
  opened,
  onClose,
  question,
  quiz,
}: {
  opened: boolean
  onClose: () => void
  question: Question
  quiz: Omit<Quiz, "id">
}) {
  // ** over-engineering** Remember last question to avoid modal flickering on exit (when question is set to null)
  const [cachedQuestion, setCachedQuestion] = useState<Nullable<Question>>(null)
  useEffect(() => {
    if (question != null) {
      setCachedQuestion(question)
    }
  }, [question])

  const screenType = quiz.screenType ?? "bandeau_horizontal"
  const Question =
    screenType === "bandeau_horizontal"
      ? CoubertinQuestion
      : ParcDesPrincesQuestion
  const screenSize = config.screenSizes[screenType]

  const backgroundSize = useMemo(
    () =>
      quiz?.screenType === "bandeau_horizontal"
        ? "1540px 220px"
        : "1920px 1080px",
    [quiz]
  )

  return (
    <Modal
      withCloseButton={false}
      opened={opened}
      onClose={onClose}
      size={"auto"}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            width: screenSize[0] * previewScale,
            height: screenSize[1] * previewScale,
          }}
        >
          <Box
            sx={{
              width: screenSize[0],
              height: screenSize[1],
              position: "relative",
              transform: `scale(${previewScale})`,
              transformOrigin: "top left",
              background: quiz.colors.background,
              backgroundImage: `url(${quiz.images.screenBackground})`,
              backgroundSize,
              backgroundRepeat: "no-repeat",
              fontFamily:
                "QuizCustomFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
            }}
          >
            {cachedQuestion && (
              <Question question={cachedQuestion} quiz={quiz} />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
