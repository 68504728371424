import { Container, Text } from "@mantine/core"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import type { Event } from "event/types/event.model"

import { WALL_WAITING_MESSAGE } from "_constants"
import QueryWrapper from "shared/components/QueryWrapper"

import { PlayLoader } from "quiz/play/components/PlayLoader"
import { PlayLayout } from "common/layouts/PlayLayout"
import { defaultWall } from "wall/admin/wall/wallForm"

import { useGetEventQuery } from "event/api/event.api"
import { useEvent } from "event/hooks/useEvent"

export function DefaultPostMessageView() {
  const event: Event | undefined = useEvent()
  const getEventQuery = useGetEventQuery(event?.id ?? skipToken)

  return (
    <PlayLayout theme={defaultWall}>
      <Container pb={"lg"}>
        {event ? (
          <QueryWrapper query={getEventQuery}>
            {(event) => (
              <PlayLoader
                message={
                  <Text
                    className="text-2xl text-center font-team-a-bold uppercase"
                    sx={{ color: defaultWall.colors.labels }}
                  >
                    {/* event.wall_questions_waiting_msg ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event.wall_questions_waiting_msg,
                        }}
                      />
                    ) : event.wall_waiting_msg ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: event.wall_waiting_msg,
                        }}
                      />
                    ) : null*/}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: event.wall_waiting_msg
                          ? event.wall_waiting_msg
                          : WALL_WAITING_MESSAGE,
                      }}
                    />
                  </Text>
                }
              />
            )}
          </QueryWrapper>
        ) : (
          <PlayLoader
            message={
              <Text
                className="text-2xl text-center font-team-a-bold uppercase"
                sx={{ color: defaultWall.colors.labels }}
              >
                L'envoi des messages n'est pas encore ouvert
              </Text>
            }
          />
        )}
      </Container>
    </PlayLayout>
  )
}
