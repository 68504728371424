import { Box, MantineProvider } from "@mantine/core"

type PlayLayoutProps = {
  theme: {
    colors: { background: string; text?: string; questionText?: string }
    images?: { playBackground: string }
  }
  children: React.ReactNode
}

export function PlayLayout({ theme, children }: PlayLayoutProps) {
  const color = theme?.colors.text || theme?.colors.questionText
  const backgroundColor = theme?.colors.background
  const backgroundImage = theme?.images?.playBackground
    ? `url(${theme?.images?.playBackground})`
    : undefined
  const styles = {
    color,
    backgroundColor,
    backgroundImage,
  }

  return (
    <Box
      className="min-w-screen overflow-hidden bg-cover bg-no-repeat font-barlow-bold"
      sx={styles}
    >
      <MantineProvider theme={{ fontFamily: "QuizCustomFont, sans-serif" }}>
        {children}
      </MantineProvider>
    </Box>
  )
}
