import { skipToken } from "@reduxjs/toolkit/query"
import QueryWrapper from "shared/components/QueryWrapper"
import { useEvent } from "event/hooks/useEvent"
import { useGetEventQuery } from "event/api/event.api"

import type { Event } from "event/types/event.model"
import type { Message } from "wall/types/message.model"

import { EventDate } from "event/play/components/EventDate"
import { EVENT_HEADER_FIRST_OPPONENT } from "_constants"

import { UseFormReturnType } from "@mantine/form"

import { PSGListNavigation } from "./PSGListNavigation"
import "event/play/common/PronosticView.css"
import "./PSGHeader.css"

type PSGHeaderProps = {
  form?: UseFormReturnType<
    Partial<Message>,
    (values: Partial<Message>) => Partial<Message>
  >
  setIsMessageSent?: React.Dispatch<React.SetStateAction<boolean>>
}
export function PSGHeader({ form, setIsMessageSent }: PSGHeaderProps) {
  const event: Event | undefined = useEvent()
  const getEventQuery = useGetEventQuery(event?.id ?? skipToken)

  if (!event) {
    return <></>
  }

  return (
    <div className="header">
      <QueryWrapper query={getEventQuery}>
        {(event) => (
          <div
            style={{
              height: "25%",
              width: "100vw",
              maxWidth: "480px",
              maxHeight: "250px",
              paddingTop: "5%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/psg/event_logo.png"
                alt="Logo"
                style={{ width: "24%", zIndex: 0 }}
              />
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#e30613",
                  width: "100%",
                  height: "55px",
                  marginLeft: "-4.5%",
                  //marginRight: "3%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="psg-text w-8/12 border-r-4 border-white"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: "Team-A-Bold",
                    color: "white",
                    textAlign: "start",
                    marginLeft: "8%",
                    marginRight: "2%",
                  }}
                >
                  <p>{EVENT_HEADER_FIRST_OPPONENT}</p>
                  <p>{event.opponent.name.toUpperCase()}</p>
                </div>
                {event ? <EventDate className="w-4/12" event={event} /> : null}
              </div>
            </div>
          </div>
        )}
      </QueryWrapper>
      <PSGListNavigation
        event={event}
        form={form}
        setIsMessageSent={setIsMessageSent}
      />
    </div>
  )
}
