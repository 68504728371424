import { useCallback } from "react";
import { openModal } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { RemoveEntityModal } from "../components/RemoveEntityModal";
import { useId } from "@mantine/hooks";

export const useOpenRemoveModal = (
  title: string,
  remove: () => Promise<unknown>,
  nextUrl: string
) => {
  const navigate = useNavigate();
  const modalId = useId();

  return useCallback(
    () =>
      openModal({
        modalId,
        title,
        withCloseButton: false,
        children: (
          <RemoveEntityModal
            modalId={modalId}
            remove={remove}
            onRemoved={() => navigate(nextUrl)}
          />
        ),
      }),
    [modalId, navigate, nextUrl, remove, title]
  );
};
