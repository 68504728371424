import { Box, Button, Group, Stack, Table, Text, Title } from "@mantine/core"
import { Link, useNavigate } from "react-router-dom"
import { Run } from "tabler-icons-react"

import Collapsable from "../../common/admin/ui/Collapsable"
import WorkforceBreadcrumbs from "./WorkforceBreadcrumbs"
import QueryWrapper from "../../shared/components/QueryWrapper"
import { useListWorkforcesQuery } from "./workforce.api"

export default function WorkforcesView() {
  const navigate = useNavigate()
  const listWorkforcesQuery = useListWorkforcesQuery()

  return (
    <Box>
      <WorkforceBreadcrumbs />

      <Title order={3} align={"center"} m={"lg"}>
        Liste des effectifs
      </Title>

      <Group position={"right"}>
        <Button className="bg-button-admin" component={Link} to="new">
          Nouvel effectif
        </Button>
      </Group>

      <Collapsable title={"Liste des effectifs"}>
        <QueryWrapper query={listWorkforcesQuery}>
          {(workforces) =>
            workforces.length ? (
              <Table highlightOnHover>
                <tbody>
                  {workforces!.map((workforce) => (
                    <tr
                      key={workforce.id}
                      onClick={() =>
                        navigate(workforce.id, { state: { workforce } })
                      }
                      style={{ display: "table-row", cursor: "pointer" }}
                    >
                      <td>
                        <Text variant="link">
                          {workforce.name || `Effectif ${workforce.id}`}
                        </Text>
                      </td>
                      <td>
                        <Group>
                          <Run />{" "}
                          <span>
                            <strong>{workforce.players.length}</strong>
                          </span>
                        </Group>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Stack align={"center"}>
                <Box>Aucun effectif trouvé</Box>
                <Button className="bg-button-admin" component={Link} to="new">
                  Nouvel effectif
                </Button>
              </Stack>
            )
          }
        </QueryWrapper>
      </Collapsable>
    </Box>
  )
}
