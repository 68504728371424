import React from "react"
import { Route, Routes } from "react-router-dom"

import { PronosticsView } from "./PronosticsView"

export function PronosticsAdminRoutes() {
  return (
    <Routes>
      <Route path="" element={<PronosticsView />} />
    </Routes>
  )
}
