import { Outlet, useParams } from "react-router-dom"
import QueryWrapper from "../../shared/components/QueryWrapper"
import { ScreenLayout } from "../../common/layouts/ScreenLayout"
import { useGetGameQuery } from "../admin/api/games.endpoints"

export function QuizScreenShellWithGameWrapper() {
  const { gameId } = useParams()
  const getGameQuery = useGetGameQuery(gameId!)

  return (
    <QueryWrapper query={getGameQuery}>
      {(game) => (
        <ScreenLayout theme={game.quizSnapshot}>
          <Outlet />
        </ScreenLayout>
      )}
    </QueryWrapper>
  )
}
