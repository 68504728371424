import { useParams } from "react-router-dom"

import { RegisteredEventHomepageView } from "event/play/pages/RegisteredEventHomepageView"
import { EventRegisterForm } from "event/play/components/EventRegisterForm"
import { useSavedEvent } from "event/hooks/useSavedEvent"
import { useGetEventQuery } from "event/api/event.api"
import { LoadingOverlay, Stack } from "@mantine/core"

export function EventHomepageView() {
  const { eventId } = useParams<{ eventId: string }>()
  const [savedEvent] = useSavedEvent(eventId!)
  const { playerId, partitionId } = savedEvent || {}
  const { data, isLoading, isSuccess } = useGetEventQuery(eventId as string)

  if (isSuccess && data) {
    return (
      <>
        {playerId && partitionId ? (
          <RegisteredEventHomepageView event={data} />
        ) : (
          <EventRegisterForm event={data} />
        )}
      </>
    )
  }
  return (
    <Stack className="h-screen justify-start" justify="center" align={"center"}>
      <LoadingOverlay visible={isLoading} />
    </Stack>
  )
}
