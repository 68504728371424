export type ScreenType = "bandeau_horizontal" | "16_9"

export type Walls = {
  maxMessageSize: number
}

export type LiveLike = {
  clientId: string
}

export type Config = {
  mock: boolean
  apiRoot: string
  wsApiRoot: string
  webAppRoot: string
  uploadRoot: string
  screenSizes: Record<ScreenType, number[]>
  walls: Walls
  livelike: LiveLike
  legalUrl: string
}

export const config: Config = {
  mock: false,
  apiRoot: "/api",
  wsApiRoot:
    process.env.REACT_APP_ENV === "staging"
      ? "wss://v4x3184pri.execute-api.eu-west-3.amazonaws.com/prod"
      : "wss://g6uemrr13f.execute-api.eu-west-3.amazonaws.com/prod",
  webAppRoot: window.location.origin,
  uploadRoot:
    process.env.REACT_APP_ENV === "staging"
      ? "https://livequizstaging-uploadbucket5b1e560c-ark3af5by7pj.s3.eu-west-3.amazonaws.com"
      : "https://livequiz-uploadbucket5b1e560c-1gajk47otv131.s3.eu-west-3.amazonaws.com",
  screenSizes: {
    bandeau_horizontal: [1540, 220],
    "16_9": [1920, 1080],
  },

  walls: {
    maxMessageSize: 70,
  },

  livelike: {
    clientId: "Uyt2WNScxd5Kqd4V4VvAE8INLAITvXfVGl3SFh01",
  },

  legalUrl: "/legal.pdf",
}
