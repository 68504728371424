import {
  ActionIcon,
  Button,
  Group,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { CirclePlus, Trash } from "tabler-icons-react";
import { merge } from "lodash";
import { useLocation, useParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import QueryWrapper from "../../shared/components/QueryWrapper";
import { useGetWorkforceQuery } from "./workforce.api";
import { skipToken } from "@reduxjs/toolkit/query";

import Collapsable from "../../common/admin/ui/Collapsable";
import WorkforceBreadcrumbs from "./WorkforceBreadcrumbs";
import WorkforceToolbar from "./WorkforceToolbar";
import PlayerView from "./PlayerView";
import { Workforce } from "./workforce.model";
import { defaultWorkforce, WorkforceFormProvider, useWorkforceForm } from "./form-context";


export default function WorkforceView() {
  const { workforceId } = useParams<{ workforceId: string }>();
  const creation = !workforceId;

  const form = useWorkforceForm({
    initialValues: defaultWorkforce,
  });
  const { setValues } = form;

  const getWorkforceQuery = useGetWorkforceQuery(workforceId ?? skipToken);

  useLayoutEffect(() => {
    const data = getWorkforceQuery.data;
    if (data) {
      setValues(merge({}, defaultWorkforce, data));
    }
  }, [getWorkforceQuery, setValues]);

  const location = useLocation();

  useLayoutEffect(() => {
    const state = location.state as { from?: Workforce } | undefined;
    if (state?.from) {
      setValues(state.from);
      window.history.replaceState(null, "");
    }
  }, [location, setValues]);

  return (
    <QueryWrapper query={getWorkforceQuery}>
      {(workforce) => (
      <WorkforceFormProvider form={form}>
        <WorkforceBreadcrumbs workforce={workforce ?? "new"} />

        <Title order={3} align={"center"} m={"lg"}>
          {creation
          ? "Nouvel effectif"
          : workforce.name || `Effectif ${workforce.id}`}
        </Title>

        <WorkforceToolbar />

        <Stack>
          <Collapsable title="Paramètres">
            <TextInput
              autoFocus={true}
              required
              sx={{ flex: 1 }}
              label={"Nom"}
              {...form.getInputProps("name")}
            />
          </Collapsable>

          {form.values.players.map((player, idx) => (
            <Collapsable
              key={idx}
              title={`Joueur #${idx + 1}`}
              actions={
                <ActionIcon
                  color="red"
                  variant="subtle"
                  onClick={() => form.removeListItem("players", idx)}
                >
                  <Trash size={16} />
                </ActionIcon>
              }
            >
              <PlayerView
                playerIdx={idx}
                form={form}
              />
            </Collapsable>
          ))}

          <Group position={"center"}>
            <Button
              variant={"subtle"}
              leftIcon={<CirclePlus />}
              onClick={() => form.insertListItem("players", {name: "", logo: ""})}
            >
              Ajouter un joueur
            </Button>
          </Group>
        </Stack>
      </WorkforceFormProvider>
      )}
    </QueryWrapper>
  )
}
