import { useNavigate } from "react-router-dom"
import { Button, Card, Stack, TextInput, Title } from "@mantine/core"
import { useForm } from "@mantine/form"
import { At, Lock } from "tabler-icons-react"

import type { LoginRequestInput, LoginResponseOutput } from "./types"
import { useLoginMutation } from "./api/auth.endpoints"
import {
  useEntity,
  useOrganization,
  useQuizToken,
  useRole,
  useSection,
} from "./hooks"
import MutationWrapper from "../../../shared/components/MutationWrapper"

export default function Login() {
  const [, setEntity] = useEntity()
  const [, setOrganisation] = useOrganization()
  const [, setQuizToken] = useQuizToken()
  const [, setRole] = useRole()
  const [, setSection] = useSection()

  const navigate = useNavigate()

  const [login, loginResult] = useLoginMutation()

  const form = useForm({
    initialValues: {
      login: "",
      password: "",
    } as LoginRequestInput,
  })

  const submit = (event: React.FormEvent) => {
    event.preventDefault()
    login(form.values as LoginRequestInput)
      .unwrap()
      .then(
        ({
          token,
          role,
          organization,
          section,
          entity,
        }: LoginResponseOutput) => {
          setQuizToken(token)
          setRole(role)
          setOrganisation(organization)
          setSection(section)
          setEntity(entity)
          navigate("/admin")
        }
      )
  }

  return (
    <form
      autoComplete="on"
      method="POST"
      style={{
        background: "#E7EBF0",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onSubmit={submit}
    >
      <Card shadow="sm" p="lg">
        <Stack spacing="xs">
          <Title order={4}>Login</Title>
          <MutationWrapper result={loginResult} title="Non." />
          <TextInput
            placeholder="Login"
            icon={<At size={14} />}
            {...form.getInputProps("login")}
          />
          <TextInput
            placeholder="Mot de passe"
            type="password"
            icon={<Lock size={14} />}
            {...form.getInputProps("password")}
          />
          <Button className="bg-button-admin" type="submit">
            Login
          </Button>
        </Stack>
      </Card>
    </form>
  )
}
