import { Route, Routes } from "react-router-dom"
import WallQRView from "wall/screen/WallQRView"
import WallScreenShell from "wall/screen/layouts/WallScreenShell"
import WallCastView from "wall/screen/cast/WallCastView"

export function WallScreenRoutes() {
  return (
    <Routes>
      <Route path=":wallId" element={<WallScreenShell />}>
        <Route path="cast" element={<WallCastView mode="messages" />} />
        <Route path="carrousel" element={<WallCastView mode="images" />} />
        <Route path="showqr" element={<WallQRView />} />
      </Route>
    </Routes>
  )
}
