export enum quizScreenType {
  "Bandeau horizontal (1540 x 220)" = "bandeau_horizontal",
  "16/9  (1920 x 1080)" = "16_9",
}

export enum quizMode {
  "Choix multiples" = "multiple_choice",
  "Juste prix" = "price_is_right",
}

export type QuestionStatus = "OPENED" | "STARTED" | "ANSWERED" | "CLOSED"

export interface MultipleChoiceQuestion {
  type?: "multiple_choice"
  label: string
  choices: {
    label: string
  }[]
  solutionIdx: number
  startTime?: number
  status?: QuestionStatus
}

export interface PriceIsRightQuestion {
  type: "price_is_right"
  label: string
  solution: number
  startTime?: number
  status?: QuestionStatus
}

export type Question = MultipleChoiceQuestion | PriceIsRightQuestion
export type Questions = MultipleChoiceQuestion[] | PriceIsRightQuestion[]

export interface Quiz {
  colors: {
    background: string
    buttonBackground: string
    buttonBorder: string
    buttonText: string
    choiceBackground: string
    choiceText: string
    choiceNumber: string
    labels: string
    questionText: string
  }
  creationTime: string
  durations: {
    question: number
    choices: number
    solution: number
    winner: number
  }
  endingMobileMsg: string
  endingMsg: string
  entity: string
  hasSeating: boolean
  id: string
  images: {
    gameOverLogo: string
    playBackground: string
    questionLogo: string
    registerLogo: string
    screenAnimatedLogo: string
    screenBackground: string
    screenBackgroundLeft: string
    waitingLogo: string
    winnerLogo: string
  }
  mode?: quizMode
  name: string
  organization: string
  questions: Questions
  section: string
  screenType: quizScreenType
  winnerMsg: string
}
