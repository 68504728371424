import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetEventQuery,
  useVotePogMutation,
  useListPogsQuery,
} from "event/api/event.api"
import { Center, Container, Image, Stack, Button } from "@mantine/core"
import type { Event } from "event/types/event.model"
import { useSavedEvent } from "../../hooks/useSavedEvent"
import QueryWrapper from "shared/components/QueryWrapper"
import { useViewportSize } from "@mantine/hooks"
import { PSGHeader } from "../components/PSGHeader"
import {
  MAN_OF_THE_MATCH_ALREADY_VOTED,
  MAN_OF_THE_MATCH_NOT_STARTED,
  MAN_OF_THE_MATCH_IN_PROGESS,
} from "_constants"

function ManOfTheMatchView() {
  const styles = {
    desktopBackground: {
      backgroundImage: "url(/psg/background_desktop.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    mobileBackground: {
      backgroundImage: "url(/psg/background_mobile.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  }
  const navigate = useNavigate()
  const { width } = useViewportSize()
  const isMobile = width <= 768
  const currentStyle = isMobile
    ? styles.mobileBackground
    : styles.desktopBackground
  const [voteState, setVoteState] = useState("notStarted")

  const { eventId } = useParams<{ eventId: string }>()
  const getEventQuery = useGetEventQuery(eventId as string)
  const [selectedPlayer, setSelectedPlayer] = useState<number | null>(null)
  const [votePog] = useVotePogMutation()
  const [savedEvent, setSavedEvent] = useSavedEvent(eventId!)

  const pogs = Object.fromEntries(
    (useListPogsQuery(eventId!).data || []).map((pog: any) => [
      pog.playerId,
      pog.votes,
    ])
  )
  const sortedEntries = Object.entries(pogs).sort(
    ([, pog_1]: [string, number], [, pog_2]: [string, number]) => pog_2 - pog_1
  )
  const maxVoteIndex = sortedEntries.length > 0 ? sortedEntries[0][0] : null

  const handleSubmit = async () => {
    if (selectedPlayer !== null) {
      try {
        await votePog({
          eventId: eventId!,
          playerId: selectedPlayer.toString(),
        })
        setVoteState("alreadyVoted")
        const { playerId, partitionId, accessToken } = savedEvent
        setSavedEvent({ playerId, partitionId, accessToken, pogVote: true })
      } catch (error) {
        console.error("Erreur lors de l'envoi du vote:", error)
      }
    }
  }

  const event = getEventQuery.data

  useEffect(() => {
    if (!savedEvent) {
      navigate("./..")
    }

    if (event && savedEvent) {
      if (event.pog_vote_ended) {
        setVoteState("finished")
      } else if (savedEvent.pogVote) {
        setVoteState("alreadyVoted")
      } else if (
        Object.values(event.pog_candidates).filter((pog: any) => pog !== null)
          .length > 0
      ) {
        setVoteState("inProgress")
      } else {
        setVoteState("notStarted")
      }
    }
  }, [event, navigate, pogs, savedEvent])

  const renderContent = (event: Event) => {
    switch (voteState) {
      case "alreadyVoted":
        return (
          <Stack style={{ flex: "1 1 0" }}>
            <Center>
              <Image
                src={"/psg/waiting_logo.png"}
                alt="Waiting Logo harcoded"
                width={65}
                sx={{
                  paddingTop: "3rem",
                }}
              />
            </Center>
            <span
              style={{
                color: "white",
                fontSize: "1.5rem",
                fontFamily: "Team-A-Bold",
                textTransform: "uppercase",
                flex: "calc(2/3) 1 0",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {MAN_OF_THE_MATCH_ALREADY_VOTED}
            </span>
          </Stack>
        )
      case "notStarted":
        return (
          <Stack style={{ flex: "1 1 0" }}>
            <Center>
              <Image
                src={"/psg/waiting_logo.png"}
                alt="Waiting Logo harcoded"
                width={65}
                sx={{
                  paddingTop: "3rem",
                }}
              />
            </Center>
            <span
              style={{
                color: "white",
                fontSize: "1.5rem",
                fontFamily: "Team-A-Bold",
                textTransform: "uppercase",
                flex: "calc(2/3) 1 0",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {MAN_OF_THE_MATCH_NOT_STARTED}
            </span>
          </Stack>
        )
      case "inProgress":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "480px",
              width: "100%",
            }}
          >
            <p
              style={{
                color: "white",
                fontFamily: "Team-A-Regular",
              }}
            >
              {MAN_OF_THE_MATCH_IN_PROGESS}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {event.pog_candidates.map((playerIndex, pogIndex) => {
                const player =
                  event.workforce_snapshot?.players[playerIndex || 0]
                return (
                  <div
                    key={playerIndex}
                    onClick={() => setSelectedPlayer(pogIndex)}
                    style={{
                      width: "50%",
                      boxSizing: "border-box",
                      textAlign: "center",
                      cursor: "pointer",
                      border:
                        selectedPlayer === pogIndex
                          ? "2px dashed white"
                          : "none",
                      padding: selectedPlayer === pogIndex ? "10px" : "12px",
                    }}
                  >
                    <Image
                      src={player?.logo}
                      alt={player?.name}
                      style={{
                        maxWidth: "40%",
                        margin: "auto",
                      }}
                    />
                    <p
                      style={{
                        color: "white",
                        fontFamily: "Team-A-Regular",
                      }}
                    >
                      {player?.name}
                    </p>
                  </div>
                )
              })}
            </div>
            <Button
              onClick={handleSubmit}
              disabled={selectedPlayer === null}
              style={{
                backgroundColor: "#e30613",
                borderColor: "white",
                color: "white",
                marginTop: "10px",
              }}
            >
              Valider
            </Button>
          </div>
        )
      case "finished":
        const mvpIndex = event.pog_candidates[(maxVoteIndex || 0) as number]
        const mvp = event.workforce_snapshot?.players[Number(mvpIndex) || 0]

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              fontFamily: "Team-A-Bold",
            }}
          >
            <p
              style={{
                color: "white",
                margin: "5px",
                fontSize: "1.4rem",
                paddingTop: "10px",
              }}
            >
              MVP DES SUPPORTERS
            </p>
            <p
              style={{
                color: "#e30613",
                margin: "5px",
                fontSize: "3rem",
                lineHeight: "1.2",
              }}
            >
              {mvp?.name.toUpperCase()}
            </p>
            <img src={mvp?.logo} alt={mvp?.name} style={{ width: "45%" }} />
          </div>
        )
    }
  }

  return (
    <QueryWrapper query={getEventQuery}>
      {(event) => {
        return (
          <Container
            className={"mobile-background"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              height: "100svh",
              maxWidth: "none",
              ...currentStyle,
            }}
          >
            <PSGHeader />
            {renderContent(event)}
          </Container>
        )
      }}
    </QueryWrapper>
  )
}

export default ManOfTheMatchView
