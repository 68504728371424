import { useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import type { Event } from "event/types/event.model"
import type { GameState } from "quiz/types/game.model"
import { useSavedEvent } from "event/hooks/useSavedEvent"
import { useSavedGame } from "quiz/play/hooks/useSavedGame"
import { PlayGameView } from "quiz/play/game/PlayGameView"
import { useGetPlayerQuery } from "quiz/admin/api/players.endpoints"
import useGame from "quiz/admin/game/useGame"

const PlayGamePresenter = () => {
  const game = useGame()
  const location = useLocation()
  const _state = location.state as { event?: Event } | undefined
  const event = _state?.event
  const savedEvent = useSavedEvent(event?.id || "")[0]

  const { gameId } = useParams()
  const [savedGame, setSavedGame] = useSavedGame(gameId!)
  const [gameState, setGameState] = useState<GameState | undefined>()

  let playerId: string
  let partitionId: string

  if (savedEvent) {
    ;({ playerId, partitionId } = savedEvent)
  } else {
    ;({ playerId, partitionId } = savedGame || {})
  }

  const { data: playerEventData } = useGetPlayerQuery([playerId, partitionId])

  return (
    <PlayGameView
      eventId={event?.id}
      gameState={gameState}
      setGameState={setGameState}
      savedEvent={savedEvent}
      savedGame={savedGame}
      setSavedGame={setSavedGame}
      gameId={gameId}
      game={game}
      playerEventData={playerEventData}
    />
  )
}

export { PlayGamePresenter }
