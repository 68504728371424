import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "shared/utils/api/apiHelpers"
import { Pronostic } from "pronostic/types/pronostic.model"

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Pronostic", Pronostic>(
    "Pronostic",
    "pronostics"
  ),
})

export const {
  useGetPronosticQuery,
  useListPronosticsQuery,
  useAddPronosticMutation,
  useRemovePronosticMutation,
  useUpdatePronosticMutation,
} = api
