import { Outlet, useParams } from "react-router-dom"

import QueryWrapper from "shared/components/QueryWrapper"
import { ScreenLayout } from "common/layouts/ScreenLayout"
import type { Wall } from "wall/types/wall.model"
import { useGetWallQuery } from "wall/api/walls.endpoints"


function WallScreenShell() {
  const { wallId } = useParams<{ wallId: string }>()
  const getWallQuery = useGetWallQuery(wallId!)

  return (
    <QueryWrapper query={getWallQuery}>
      {(wall: Wall) => (
        <ScreenLayout theme={{ ...wall, screenType: wall.template }}>
          <Outlet />
        </ScreenLayout>
      )}
    </QueryWrapper>
  )
}

export default WallScreenShell
