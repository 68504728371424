import { useCallback, useLayoutEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { merge } from "lodash"
import {
  Button,
  Center,
  Divider,
  Group,
  NumberInput,
  Select,
  Stack,
  Switch,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core"
import { DatePicker, TimeInput } from "@mantine/dates"
import { skipToken } from "@reduxjs/toolkit/query"

import type { Event } from "event/types/event.model"
import type { FoodMenu } from "click-and-collect/types/foodMenu.model"
import type { Game } from "quiz/types/game.model"
import type { Wall } from "wall/types/wall.model"
import type { Workforce } from "workforce/admin/workforce.model"

import {
  EVENT_CONTACT_FORM_CHECKBOX_LINK,
  EVENT_CONTACT_FORM_CHECKBOX_MESSAGE,
  EVENT_CONTACT_FORM_VALIDATION_BUTTON,
  EVENT_CONTACT_FORM_WELCOME,
  EVENT_LANDING_MSG,
} from "_constants"
import {
  ADMIN_ORGANIZATION,
  EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  ORGANIZATIONS,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import Collapsable from "common/admin/ui/Collapsable"
import FileInput from "common/admin/ui/FileInput"
import QueryWrapper from "shared/components/QueryWrapper"
import {
  useGetEventQuery,
  useUpdateEventMutation,
  useListPogsQuery,
} from "event/api/event.api"
import EventBreadcrumbs from "event/admin/components/EventBreadcrumbs"
import EventToolbar from "event/admin/components/EventToolbar"
import PlayerSelect from "event/admin/components/PlayerSelect"
import {
  defaultEvent,
  EventFormProvider,
  useEventForm,
} from "event/admin/context/form-context"
import { useListFoodMenusQuery } from "click-and-collect/api/foodMenu.api"
import { useListGamesQuery } from "quiz/admin/api/games.endpoints"
import { useListWallsQuery } from "wall/api/walls.endpoints"
import { useListWorkforcesQuery } from "workforce/admin/workforce.api"

const goal_names = ["Andreas Palick", "Jannick Green"]

const EventView = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const creation = !eventId

  const form = useEventForm({
    initialValues: defaultEvent,
    validate: {
      opponent: {
        name: (value) => {
          if (!value) return "Le nom de l'adversaire est requis."
        },
      },
      kickoff_date(value) {
        if (creation && new Date(value) < new Date())
          return "Le coup d'envoi doit être dans le futur."
      },
      workforce_snapshot(value: Workforce | null) {
        if (!value) return "Un effectif doit être sélectionné."
      },
    },
  })
  const { setValues } = form
  const organization = form.values.organization

  const queryFiltersByOrganization = (
    organization: string | undefined
  ): Record<string, string> | undefined => {
    if (
      organization &&
      organization !== "" &&
      organization !== "136" &&
      organization !== ADMIN_ORGANIZATION
    ) {
      return { organization }
    }
    return
  }

  const getEventQuery = useGetEventQuery(eventId ?? skipToken)

  const listFoodMenusQuery = useListFoodMenusQuery(
    queryFiltersByOrganization(organization)
  )

  const listGamesQuery = useListGamesQuery(
    queryFiltersByOrganization(organization)
  )

  const listWallsQuery = useListWallsQuery(
    queryFiltersByOrganization(organization)
  )

  const listWorkforcesQuery = useListWorkforcesQuery(
    queryFiltersByOrganization(organization)
  )

  useLayoutEffect(() => {
    const data = getEventQuery.data
    if (data) {
      setValues(
        merge({}, defaultEvent, data, {
          kickoff_date: new Date(data.kickoff_date).toISOString(),
        })
      )
    }
  }, [getEventQuery, setValues])

  const location = useLocation()

  useLayoutEffect(() => {
    const state = location.state as { from?: Event } | undefined
    if (state?.from) {
      setValues(state.from)
      window.history.replaceState(null, "")
    }
  }, [location, setValues])

  const [updateEvent] = useUpdateEventMutation()
  const pogs = Object.fromEntries(
    (useListPogsQuery(eventId!).data || []).map((pog: any) => [
      pog.playerId,
      pog.votes,
    ])
  )

  const [endingVote, setEndingVote] = useState<boolean>(false)

  const filterFoodMenuByOrganization =
    (organization: string | undefined) =>
    (foodMenu: FoodMenu): boolean => {
      if (organization !== "136") {
        return foodMenu.organization === organization
      }
      return true
    }

  const filterFoodMenus = useCallback(
    (foodMenus: FoodMenu[]) => {
      const foodMenuId = form.getInputProps("foodMenu").value
      const matchingFoodMenu: FoodMenu[] = foodMenus.filter(
        (foodMenu) => foodMenu.id === foodMenuId
      )
      let filteredFoodMenus = foodMenus
      if (organization && organization !== "136") {
        filteredFoodMenus = foodMenus.filter(
          filterFoodMenuByOrganization(form.getInputProps("organization").value)
        )
      }
      const results = [...filteredFoodMenus, ...matchingFoodMenu]
      return results
        .sort(function (a, b) {
          if (a.creationTime > b.creationTime) return -1
          if (a.creationTime < b.creationTime) return +1
          return 0
        })
        .sort(function (a, b) {
          if (a.creationTime > b.creationTime) return -1
          if (a.creationTime < b.creationTime) return +1
          return 0
        })
        .map((foodMenu) => ({
          label: `${foodMenu.label} --- ${foodMenu.organization}`,
          value: foodMenu.id,
        }))
    },
    [form, organization]
  )

  const filterGamesByOrganization =
    (organization: string | undefined) =>
    (game: Game): boolean => {
      if (organization !== "136") {
        return (
          game.state.step === "CREATED" &&
          game.quizSnapshot?.organization === organization
        )
      }
      return game.state.step === "CREATED"
    }

  const filterGames = useCallback(
    (games: Game[]) => {
      const eventQuizId = form.getInputProps("quiz_id").value
      const matchingGame: Game[] = games.filter(
        (game) => game.quizId === eventQuizId
      )
      let filteredGames = games
      if (organization && organization !== "136") {
        filteredGames = games.filter(
          filterGamesByOrganization(form.getInputProps("organization").value)
        )
      }
      const results = [...filteredGames, ...matchingGame]
      return results
        .sort(function (a, b) {
          if (a.quizSnapshot.creationTime > b.quizSnapshot.creationTime)
            return -1
          if (a.quizSnapshot.creationTime < b.quizSnapshot.creationTime)
            return +1
          return 0
        })
        .sort(function (a, b) {
          if (a.creationTime > b.creationTime) return -1
          if (a.creationTime < b.creationTime) return +1
          return 0
        })
        .map((game) => ({
          label: `${game.quizSnapshot?.name} --- ${game.name}`,
          value: game.id,
        }))
    },
    [form, organization]
  )

  const filterWallByOrganization =
    (organization: string | undefined) =>
    (wall: Wall): boolean => {
      if (organization) {
        return wall.organization === organization
      }
      return true
    }

  const filterDiaporamas = useCallback(
    (walls: Wall[]) => {
      let filteredWalls = walls
      if (organization && organization !== "136") {
        filteredWalls = walls.filter(filterWallByOrganization(organization))
      }
      return filteredWalls.map((wall) => ({
        label: wall.name || `Diaporama ${wall.id}`,
        value: wall.id,
      }))
    },
    [organization]
  )

  return (
    <QueryWrapper
      queries={[
        getEventQuery,
        listFoodMenusQuery,
        listGamesQuery,
        listWallsQuery,
        listWorkforcesQuery,
      ]}
    >
      {([event, foodMenus, games, walls, workforces]) => (
        <EventFormProvider form={form}>
          <EventBreadcrumbs event={event ?? "new"} />

          <Title order={3} align={"center"} m={"lg"}>
            {creation
              ? "Nouvel événement"
              : event.opponent.name
              ? `PSG ${event.opponent.name}`
              : `Événement ${event.id}`}
          </Title>

          <EventToolbar />

          <Stack>
            <Collapsable title="Paramètres">
              <Stack>
                <AccessRights
                  authorizedOrganizations={
                    EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
                  }
                  authorizedRoles={
                    EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
                  }
                >
                  <Select
                    sx={{ flex: 1 }}
                    label={"Organisme"}
                    data={ORGANIZATIONS}
                    {...form.getInputProps("organization")}
                  />
                </AccessRights>
                <Select
                  required
                  label={"Effectif du match"}
                  placeholder={"Choix de l'effectif"}
                  disabled={!creation && event.workforce_snapshot}
                  data={workforces.map((workforce) => ({
                    label: workforce.name,
                    value: workforce.id,
                  }))}
                  {...form.getInputProps("workforce_snapshot")}
                  value={form.values.workforce_snapshot?.id}
                  onChange={(value: string | null) => {
                    for (const workforce of workforces) {
                      if (workforce.id === value) {
                        form.setFieldValue("workforce_snapshot", workforce)
                        break
                      }
                    }
                  }}
                />
                <TextInput
                  required
                  sx={{ flex: 1 }}
                  label={"Nom de l'adversaire"}
                  disabled={!creation && event.opponent?.name}
                  {...form.getInputProps("opponent.name")}
                />
                <FileInput
                  label={"Logo de l'adversaire"}
                  {...form.getInputProps("opponent.logo")}
                />
                <Group grow>
                  <DatePicker
                    required
                    label={"Date de l'événement"}
                    disabled={!creation}
                    {...form.getInputProps("kickoff_date")}
                    value={new Date(form.values.kickoff_date)}
                    onChange={(value: string | null) => {
                      if (!value) return

                      const newDate = new Date(value)
                      const eventDate = new Date(form.values.kickoff_date)
                      form.setFieldValue(
                        "kickoff_date",
                        new Date(
                          eventDate.setFullYear(
                            newDate.getFullYear(),
                            newDate.getMonth(),
                            newDate.getDate()
                          )
                        ).toISOString()
                      )
                    }}
                  />
                  <TimeInput
                    required
                    label={"Heure de l'événement"}
                    disabled={!creation}
                    {...form.getInputProps("kickoff_date")}
                    value={new Date(form.values.kickoff_date)}
                    onChange={(value: string | null) => {
                      if (!value) return

                      const newDate = new Date(value)
                      const eventDate = new Date(form.values.kickoff_date)
                      form.setFieldValue(
                        "kickoff_date",
                        new Date(
                          eventDate.setHours(
                            newDate.getHours(),
                            newDate.getMinutes()
                          )
                        ).toISOString()
                      )
                    }}
                  />
                </Group>
              </Stack>
            </Collapsable>
            <Collapsable title="Textes">
              <Textarea
                key="register-form-welcome-msg"
                label="Introduction"
                name="registerFormWelcomeMsg"
                placeholder={EVENT_CONTACT_FORM_WELCOME}
                {...form.getInputProps("labels.register_form_welcome_msg")}
              />
              <Textarea
                key="register-form-checkbox-msg"
                label="Règlement"
                name="registerFormCheckboxMsg"
                placeholder={EVENT_CONTACT_FORM_CHECKBOX_MESSAGE}
                {...form.getInputProps("labels.register_form_checkbox_msg")}
              />
              <Textarea
                key="register-form-checkbox-link"
                label="Lien Téléchargement"
                name="registerFormCheckboxLink"
                placeholder={EVENT_CONTACT_FORM_CHECKBOX_LINK}
                {...form.getInputProps("labels.register_form_checkbox_link")}
              />
              <Textarea
                key="register-form-thanks-msg"
                label="Message bas de page formulaire"
                name="registerFormThanksMsg"
                placeholder={EVENT_CONTACT_FORM_WELCOME}
                {...form.getInputProps("labels.register_form_thanks_msg")}
              />
              <Textarea
                key="registered-welcome-msg"
                label="Message accueil"
                name="registeredWelcomeMsg"
                placeholder={EVENT_LANDING_MSG}
                {...form.getInputProps("labels.registered_welcome_msg")}
              />
              <Textarea
                key="register-form-validation-button"
                label="Libellé bouton"
                name="registerFormValidationButton"
                placeholder={EVENT_CONTACT_FORM_VALIDATION_BUTTON}
                {...form.getInputProps(
                  "labels.register_form_validation_button"
                )}
              />
            </Collapsable>
            <Collapsable title="Quiz associé au match">
              <Switch
                className="pb-3"
                checked={form.values.quiz_display}
                label="Afficher le quiz"
                onLabel="Oui"
                offLabel="Non"
                onChange={(event) => {
                  const value = event.currentTarget.checked
                  form.setFieldValue("quiz_display", value)
                }}
                {...form.getInputProps("quiz_display")}
              />
              <Select
                placeholder={"Choix de la partie"}
                data={filterGames(games)}
                clearable={true}
                {...form.getInputProps("quiz_id")}
              />
            </Collapsable>
            <Collapsable title="Diaporama pour les messages">
              <Switch
                className="pb-3"
                checked={form.values.selfies_display}
                label="Afficher les diaporamas"
                onLabel="Oui"
                offLabel="Non"
                onChange={(event) => {
                  const value = event.currentTarget.checked
                  form.setFieldValue("selfies_display", value)
                }}
                {...form.getInputProps("selfies_display")}
              />
              <Select
                placeholder={"Choix du diaporama"}
                data={filterDiaporamas(walls)}
                clearable={true}
                {...form.getInputProps("wall_id")}
              />
              <Textarea
                key="wall-waiting-msg"
                label="Texte par défaut"
                name="wallWaitingMsg"
                placeholder="L'envoi des messages n'est pas encore ouvert"
                {...form.getInputProps("wall_waiting_msg")}
              />
            </Collapsable>
            <Collapsable title="Posez vos questions">
              <Switch
                className="pb-3"
                checked={form.values.questions_display}
                label="Afficher les questions"
                onLabel="Oui"
                offLabel="Non"
                onChange={(event) => {
                  const value = event.currentTarget.checked
                  form.setFieldValue("questions_display", value)
                }}
                {...form.getInputProps("questions_display")}
              />
              <Select
                placeholder={"Choix du diaporama"}
                data={filterDiaporamas(walls)}
                clearable={true}
                {...form.getInputProps("wall_questions_id")}
              />
              <Textarea
                key="wall-questions-waiting-msg"
                label="Texte par défaut"
                name="wallQuestionsWaitingMsg"
                placeholder="L'envoi des questions est terminé. Profitez du match !"
                {...form.getInputProps("wall_questions_waiting_msg")}
              />
            </Collapsable>
            <Collapsable title="Homme du match">
              <Switch
                className="pb-3"
                checked={form.values.man_of_the_match_display}
                label="Afficher l'homme du match"
                onLabel="Oui"
                offLabel="Non"
                onChange={(event) => {
                  const value = event.currentTarget.checked
                  form.setFieldValue("man_of_the_match_display", value)
                }}
                {...form.getInputProps("man_of_the_match_display")}
              />
              <Stack>
                <Group grow>
                  {form.values.pog_candidates.map((pog_candidate, index) => (
                    <Stack key={index}>
                      <PlayerSelect
                        disabled={
                          event?.pog_candidates.filter(
                            (candidate) => candidate != null
                          ).length > 0
                        }
                        label={<Title order={4}>Joueur {index + 1}</Title>}
                        placeholder={"Sélectionner un joueur"}
                        players={form.values.workforce_snapshot?.players}
                        value={pog_candidate?.toString()}
                        onChange={(value: string | null) =>
                          value &&
                          form.setFieldValue(
                            `pog_candidates.${index}`,
                            Number(value)
                          )
                        }
                      />
                      Votes : {pogs[index] || 0}
                    </Stack>
                  ))}
                </Group>
                {eventId &&
                  ((!endingVote && (
                    <Button
                      className="bg-button-admin"
                      disabled={event.pog_vote_ended}
                      onClick={() => setEndingVote(true)}
                    >
                      Arrêter le vote
                    </Button>
                  )) || (
                    <Stack>
                      <Center style={{ fontWeight: "bold" }}>
                        Êtes-vous sûr de vouloir arrêter le vote ?
                      </Center>
                      <Group grow>
                        <Button
                          onClick={() => {
                            updateEvent({
                              id: eventId,
                              ...form.values,
                              pog_vote_ended: true,
                            })
                            setEndingVote(false)
                          }}
                        >
                          Oui
                        </Button>
                        <Button
                          variant="outline"
                          onClick={() => setEndingVote(false)}
                        >
                          Non
                        </Button>
                      </Group>
                    </Stack>
                  ))}
              </Stack>
            </Collapsable>
            <Collapsable title="Pronostics">
              <Switch
                className="pb-3"
                checked={form.values.pronostics_display}
                label="Afficher les pronostics"
                onLabel="Oui"
                offLabel="Non"
                onChange={(event) => {
                  const value = event.currentTarget.checked
                  form.setFieldValue("pronostics_display", value)
                }}
                {...form.getInputProps("pronostics_display")}
              />
              <Stack spacing={"xl"}>
                <Stack spacing={"xs"}>
                  <Divider
                    label={<Title order={3}>Score en fin de match</Title>}
                  />
                  <Group grow>
                    <Stack>
                      <NumberInput
                        min={0}
                        label={<Title order={4}>PSG</Title>}
                        {...form.getInputProps("results.match_end_score.own")}
                      />
                    </Stack>
                    <Stack>
                      <NumberInput
                        min={0}
                        label={
                          <Title order={4}>
                            {form.values.opponent.name || "Adversaire"}
                          </Title>
                        }
                        {...form.getInputProps(
                          "results.match_end_score.opponent"
                        )}
                      />
                    </Stack>
                  </Group>
                </Stack>
                <Divider />
                <Group grow>
                  <Title order={3}>Meilleur buteur</Title>
                  <PlayerSelect
                    placeholder={"Sélectionner un joueur"}
                    players={form.values.workforce_snapshot?.players}
                    value={form.values.results.best_scorer?.toString()}
                    onChange={(value: string | null) =>
                      value &&
                      form.setFieldValue("results.best_scorer", Number(value))
                    }
                  />
                </Group>
                <Group grow>
                  <Title order={3}>Dernier buteur</Title>
                  <PlayerSelect
                    placeholder={"Sélectionner un joueur"}
                    players={form.values.workforce_snapshot?.players}
                    value={form.values.results.last_scorer?.toString()}
                    onChange={(value: string | null) =>
                      value &&
                      form.setFieldValue("results.last_scorer", Number(value))
                    }
                  />
                </Group>
                <Group grow>
                  <Title order={3}>Buts arrêtés</Title>
                  {form.values.results.goals_stopped.map((_, index) => (
                    <NumberInput
                      key={index}
                      label={goal_names[index]}
                      min={0}
                      {...form.getInputProps(`results.goals_stopped.${index}`)}
                    />
                  ))}
                </Group>
              </Stack>
            </Collapsable>
            <Collapsable title="Menu">
              <Switch
                className="pb-3"
                checked={form.values.food_menu_display}
                label="Afficher le menu"
                onLabel="Oui"
                offLabel="Non"
                onChange={(event) => {
                  const value = event.currentTarget.checked
                  form.setFieldValue("food_menu_display", value)
                }}
                {...form.getInputProps("food_menu_display")}
              />
              <Select
                placeholder={"Choix du menu"}
                data={filterFoodMenus(foodMenus)}
                clearable={true}
                {...form.getInputProps("food_menu")}
              />
            </Collapsable>
          </Stack>
        </EventFormProvider>
      )}
    </QueryWrapper>
  )
}

export { EventView }
