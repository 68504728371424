import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { ActionIcon, Button, Group, LoadingOverlay, Menu } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { isEqual } from "lodash"
import { ArrowBack, Copy, Settings, Trash } from "tabler-icons-react"
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import { MutationDefinition } from "@reduxjs/toolkit/query"

import {
  ENTITY_DELETE_AUTHORISED_ORGANIZATIONS,
  ENTITY_DELETE_AUTHORISED_ROLES,
} from "_roles"
import { useOpenRemoveModal } from "shared/utils/useOpenRemoveModal"
import { throwError } from "shared/utils/api/apiHelpers"
import { AccessRights } from "./auth/AccessRights"

function EntityToolbar<T extends { id: string }>({
  entity,
  form,
  addMutation,
  updateMutation,
  removeMutation,
  label,
  children,
}: {
  entity: T | undefined
  form: UseFormReturnType<Omit<T, "id">>
  addMutation: ReturnType<UseMutation<MutationDefinition<any, any, any, any>>>
  updateMutation: ReturnType<
    UseMutation<MutationDefinition<any, any, any, any>>
  >
  removeMutation: ReturnType<
    UseMutation<MutationDefinition<any, any, any, any>>
  >
  label: string
  children?: React.ReactNode
}) {
  const isDirty = useMemo(() => !isEqual(entity, form.values), [entity, form])
  const navigate = useNavigate()

  const [addEntity, addEntityStatus] = addMutation
  const [updateEntity, updateEntityStatus] = updateMutation
  const [removeEntity] = removeMutation

  const pronoun = label[0] === "é" ? "cet" : "ce"
  const openRemoveModal = useOpenRemoveModal(
    `Supprimer ${pronoun} ${label} ?`,
    () => removeEntity(entity?.id),
    "./.."
  )

  const save = async () => {
    if (entity) {
      updateEntity({ id: entity.id, ...form.values })
    } else {
      const newEntity = await addEntity(form.values).then(throwError)
      navigate("./../" + newEntity.id)
    }
  }

  const duplicate = () =>
    navigate("./../new", { state: { from: { ...form.values, name: "" } } })

  return (
    <Group position={"right"}>
      <LoadingOverlay
        visible={addEntityStatus.isLoading || updateEntityStatus.isLoading}
      />

      <Button
        className="bg-button-admin disabled:opacity-75"
        disabled={!isDirty}
        onClick={() => {
          if (form.validate().hasErrors) {
            return
          }
          save()
        }}
      >
        Sauvegarder
      </Button>

      {entity && (
        <Menu transition="pop" shadow="md" position={"bottom-end"}>
          <Menu.Target>
            <ActionIcon size={"lg"} className="text-button-admin">
              <Settings />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              icon={<ArrowBack size={14} />}
              disabled={!isDirty}
              onClick={() => {
                form.setValues(entity)
              }}
            >
              Annuler les changements
            </Menu.Item>

            <Menu.Item icon={<Copy size={14} />} onClick={duplicate}>
              Dupliquer
            </Menu.Item>
            <AccessRights
              authorizedRoles={ENTITY_DELETE_AUTHORISED_ROLES}
              authorizedOrganizations={ENTITY_DELETE_AUTHORISED_ORGANIZATIONS}
            >
              <Menu.Item
                className="text-warning"
                icon={<Trash size={14} />}
                onClick={openRemoveModal}
              >
                Supprimer
              </Menu.Item>
            </AccessRights>

            {children && (
              <>
                <Menu.Divider />
                {children}
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  )
}

export default EntityToolbar
