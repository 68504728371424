import React, { useMemo, useState } from "react";
import {
  ActionIcon,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Circle, Refresh } from "tabler-icons-react";
import useGame from "./useGame";
import { useListPlayersQuery } from "../api/players.endpoints";
import QueryWrapper from "../../../shared/components/QueryWrapper";
import { chunk } from "lodash";

const GamePlayers: React.FC<{
  onNumPlayersChanged?: (numPlayers: number) => void;
}> = ({ onNumPlayersChanged = () => {} }) => {
  const game = useGame()!;
  const listPlayersQuery = useListPlayersQuery({ gameId: game.id });
  const [pageIndex, setPageIndex] = useState(0);

  const theme = useMantineTheme();

  const pages = useMemo(
    () => chunk(listPlayersQuery.data || [], 20),
    [listPlayersQuery.data]
  );

  return (
    <QueryWrapper query={listPlayersQuery}>
      {(players) => (
        <Stack>
          <Group position={"apart"}>
            <Group spacing={"sm"} position={"right"}>
              <ActionIcon
                variant={"default"}
                size={36}
                onClick={listPlayersQuery.refetch}
              >
                <Refresh size={18} />
              </ActionIcon>
              <span>
                <strong>{listPlayersQuery.data?.length}</strong> inscrits
              </span>
            </Group>
            <Pagination
              position={"right"}
              page={pageIndex + 1}
              onChange={(page) => setPageIndex(page - 1)}
              total={pages.length}
            />
          </Group>

          <Table highlightOnHover>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Score</th>
                {game?.quizSnapshot?.mode === "price_is_right" && (
                  <th>Réponse</th>
                )}
                <th>Téléphone</th>
                {game?.quizSnapshot?.screenType === "bandeau_horizontal" ? (
                    <th>Tribune</th>
                ) : (
                    <th>Accès</th>
                )}
                <th>Rang</th>
                {game?.quizSnapshot?.screenType === "bandeau_horizontal" ? (
                    <th>Place</th>
                ) : (
                    <th>Siège</th>
                )}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(pages[pageIndex] || []).map((player, idx) => (
                <tr
                  key={player.id}
                  style={{
                    background:
                      game?.state?.leaderboard?.[0].id === player.id
                        ? theme.colors.green[1]
                        : undefined,
                  }}
                >
                  <td>
                    <Text variant="link">
                      {player.firstName} {player.lastName}
                    </Text>
                  </td>
                  <td>{player.score === -1 ? "-" : player.score}</td>
                  {game?.quizSnapshot?.mode === "price_is_right" && (
                    <td>{player.answers?.[0] === undefined ? "Aucune" : player.answers?.[0]}</td>
                  )}
                  <td>{player.phoneNumber}</td>
                  <td>{player.accessNumber}</td>
                  <td>{player.rankNumber}</td>
                  <td>{player.seatNumber}</td>
                  <td style={{ textAlign: "right" }}>
                    <Circle
                      size={10}
                      fill={
                        player.connected
                          ? theme.colors.green[4]
                          : theme.colors.red[4]
                      }
                      stroke={"none"}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            position={"right"}
            page={pageIndex + 1}
            onChange={(page) => setPageIndex(page - 1)}
            total={pages.length}
          />
        </Stack>
      )}
    </QueryWrapper>
  );
};

export default GamePlayers;
