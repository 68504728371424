import { apiSlice } from "core/api"
import { makeCrudEndpoints } from "shared/utils/api/apiHelpers"
import { FoodMenu } from "click-and-collect/types/foodMenu.model"

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"FoodMenu", FoodMenu>("FoodMenu", "food-menus"),
})

export const {
  useGetFoodMenuQuery,
  useListFoodMenusQuery,
  useAddFoodMenuMutation,
  useRemoveFoodMenuMutation,
  useUpdateFoodMenuMutation,
} = api
