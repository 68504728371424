import { createFormContext } from "@mantine/form"
import {
  EVENT_CONTACT_FORM_CHECKBOX_LINK,
  EVENT_CONTACT_FORM_CHECKBOX_MESSAGE,
  EVENT_CONTACT_FORM_THANKS,
  EVENT_CONTACT_FORM_VALIDATION_BUTTON,
  EVENT_CONTACT_FORM_WELCOME,
  EVENT_LANDING_MSG,
  WALL_QUESTIONS_WAITING_MESSAGE,
  WALL_WAITING_MESSAGE,
} from "_constants"

import type { Event } from "event/types/event.model"

export const [EventFormProvider, useEventFormContext, useEventForm] =
  createFormContext<Omit<Event, "id">>()

export const defaultEvent: Omit<Event, "id"> = {
  entity: "",
  kickoff_date: new Date().toISOString(),
  labels: {
    register_form_checkbox_link: EVENT_CONTACT_FORM_CHECKBOX_LINK,
    register_form_checkbox_msg: EVENT_CONTACT_FORM_CHECKBOX_MESSAGE,
    register_form_thanks_msg: EVENT_CONTACT_FORM_THANKS,
    register_form_validation_button: EVENT_CONTACT_FORM_VALIDATION_BUTTON,
    register_form_welcome_msg: EVENT_CONTACT_FORM_WELCOME,
    registered_welcome_msg: EVENT_LANDING_MSG,
  },
  livelike_program: {
    leaderboard_id: null,
    program_id: null,
    widget_ids: {
      best_scorer: null,
      goals_stopped: null,
      last_scorer: null,
      match_end_score: null,
    },
  },
  opponent: {
    logo: "",
    name: "",
  },
  organization: "",
  pog_candidates: [null, null, null, null],
  pog_vote_ended: false,
  quiz_id: null,
  results: {
    best_scorer: null,
    best_scorer_goals: null,
    first_scorer: null,
    goals_stopped: [null, null],
    half_time_score: { own: null, opponent: null },
    last_scorer: null,
    match_end_score: { own: null, opponent: null },
  },
  section: "",
  wall_id: null,
  wall_waiting_msg: WALL_WAITING_MESSAGE,
  wall_questions_waiting_msg: WALL_QUESTIONS_WAITING_MESSAGE,
  workforce_snapshot: null,
}
