import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Modal, Stack, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"

import { gameService } from "services/services"
import type { Game } from "quiz/types/game.model"
import type { Quiz } from "quiz/types/quiz.model"
import { useOrganization, useEntity, useSection } from "common/admin/auth/hooks"

export default function CreateGameModal({
  opened,
  onClose,
  quizSnapshot,
}: {
  opened: boolean
  onClose: () => void
  quizSnapshot: Quiz
}) {
  const [organization] = useOrganization()
  const [entity] = useEntity()
  const [section] = useSection()
  const form = useForm({
    initialValues: {
      name: "",
      state: { step: "CREATED" },
      organization: organization ? organization : "",
      entity: entity ? entity : "",
      section: section ? section : "",
    } as Omit<Game, "id">,
  })

  const [saving, setSaving] = useState(false)

  const { quizId } = useParams() as { quizId: string }
  const navigate = useNavigate()

  const submit = useCallback(
    (values: Omit<Game, "id">) => {
      setSaving(true)
      gameService.create({ ...values, quizId, quizSnapshot }).then((game) =>
        navigate(`./games/${game.id}`, {
          state: { game },
        })
      )
    },
    [navigate, quizId, quizSnapshot]
  )

  useEffect(() => {
    const today = new Date()
    const placeholderFormat = `${today.getFullYear()}${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${today.getDate().toString().padStart(2, "0")}`
    form.setFieldValue("name", `${placeholderFormat} - `)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal withCloseButton={false} opened={opened} onClose={onClose}>
      <form onSubmit={form.onSubmit(submit)}>
        <Stack>
          <TextInput
            label={"Nom de la partie"}
            {...form.getInputProps("name")}
          />
          <Button className="bg-button-admin" type={"submit"} disabled={saving}>
            Créer
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}
