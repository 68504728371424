import { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"
import {
  Container,
  Button,
  Center,
  Group,
  Image,
  Loader,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { Check, X } from "tabler-icons-react"

import { Nullable } from "types"
import type {
  MultipleChoiceQuestion,
  Question,
  QuestionStatus,
  Quiz,
} from "quiz/types/quiz.model"

import { default as CoubertinTimer } from "screen/coubertin/Timer"
import { default as ParcDesPrincesTimer } from "screen/parc_des_princes/Timer"
import { PSGHeader } from "event/play/components/PSGHeader"

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

export default function Choices({
  question,
  questionStatus,
  quiz,
  answerIdx,
  solutionIdx,
  answer,
}: {
  question: Question
  questionStatus: QuestionStatus
  quiz: Quiz
  answerIdx: Nullable<number>
  solutionIdx: Nullable<number>
  answer: (answerIdx: number) => void
}) {
  const [showTimer, setShowTimer] = useState(false)
  const [localAnswerIdx, setLocalAnswerIdx] = useState<Nullable<number>>()
  const [hasAnswerBeenClicked, setHasAnswerBeenClicked] = useState(false)

  useEffect(() => {
    if (answerIdx === null && localAnswerIdx !== null) {
      setLocalAnswerIdx(null)
    }
    if (solutionIdx !== null && hasAnswerBeenClicked) {
      setHasAnswerBeenClicked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerIdx, solutionIdx])

  const [requestedAnswer, setRequestedAnswer] = useState<Nullable<number>>()

  const { question: questionDuration } = quiz.durations

  const animation = useAnimation()
  const startAnimation = animation.start

  useEffect(() => {
    if (questionStatus === "OPENED") {
      startAnimation("initial")
      if (question.type === "price_is_right") {
        setTimeout(() => startAnimation("questions"), 3000)
        setTimeout(
          () => startAnimation("choices"),
          (questionDuration + 0.1) * 1000
        )
      } else {
        setTimeout(() => startAnimation("choices"), 3000)
      }
    }
    if (questionStatus === "STARTED") {
      setShowTimer(true)
    }
    if (questionStatus === "CLOSED") {
      setShowTimer(false)
      startAnimation("solution")
    }
  }, [question, questionDuration, questionStatus, startAnimation])

  const screenType = quiz.screenType ?? "bandeau_horizontal"
  const Timer =
    screenType === "bandeau_horizontal" ? CoubertinTimer : ParcDesPrincesTimer

  return (
    <Container
      sx={{
        minHeight: "100dvh",
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <PSGHeader />
      <Center>
        <Image
          src={quiz.images.questionLogo}
          width={75}
          style={{ paddingBottom: "10px" }}
          alt="Question Logo"
        />
      </Center>

      <motion.div
        initial={{ position: "relative", right: "100vw" }}
        animate={{ right: "0vw" }}
        transition={{ type: "spring", bounce: 0 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "95%",
            margin: "0 auto",
            paddingBottom: "2vh",
          }}
        >
          <h2
            style={{
              fontSize: "1.2rem",
              fontFamily: "Team-A-Bold",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{ __html: question.label }}
          ></h2>
        </div>
      </motion.div>
      <motion.div
        initial="initial"
        animate={animation}
        variants={{
          initial: { opacity: 0 },
          choices: {
            opacity: 1,
            transition: { staggerChildren: 0.1 },
          },
        }}
      >
        {[undefined, "multiple_choice"].includes(question.type) && (
          <Stack
            style={{
              width: "350px",
            }}
          >
            {(question as MultipleChoiceQuestion).choices.map((choice, idx) => (
              <motion.div
                key={idx}
                variants={{
                  hidden: { position: "relative", right: "100vw" },
                  show: {
                    right: "0vw",
                    transition: { type: "spring", bounce: 0 },
                  },
                }}
              >
                <Group
                  sx={{
                    background: quiz.colors.choiceBackground,
                    borderRadius: 25,
                    gridColumn: "span 3 / span 3",
                    justifyContent: "space-between",
                    paddingRight: "5vw",
                    paddingLeft: "5vw",
                    opacity:
                      localAnswerIdx !== null && localAnswerIdx !== idx
                        ? 0.5
                        : 1,
                  }}
                  key={choice.label}
                  p={"sm"}
                  onClick={() => {
                    if (
                      hasAnswerBeenClicked ||
                      answerIdx != null ||
                      localAnswerIdx != null ||
                      solutionIdx != null ||
                      !showTimer
                    ) {
                      return
                    }
                    setHasAnswerBeenClicked(true)
                    setLocalAnswerIdx(idx)
                    answer(idx)
                  }}
                >
                  <div className="flex">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white",
                        color: "red",
                        width: "1.5em",
                        height: "1.5em",
                        fontFamily: "Team-A-Bold",
                      }}
                    >
                      {letters[idx]}.
                    </div>
                    <Text
                      sx={{
                        color: quiz.colors.choiceText,
                        flexGrow: 1,
                        fontFamily: "Team-A-Bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {choice.label}
                    </Text>
                  </div>
                  {hasAnswerBeenClicked &&
                  localAnswerIdx !== null &&
                  localAnswerIdx === idx &&
                  questionStatus !== "ANSWERED" ? (
                    <Loader size={30} sx={{ color: quiz.colors.choiceText }} />
                  ) : null}
                  {solutionIdx != null && solutionIdx === idx ? (
                    <Check
                      size={30}
                      strokeWidth={3}
                      style={{
                        color: quiz.colors.choiceText,
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                  ) : null}
                  {solutionIdx != null &&
                  solutionIdx !== idx &&
                  idx === answerIdx &&
                  questionStatus === "ANSWERED" ? (
                    <X
                      size={30}
                      strokeWidth={3}
                      style={{
                        color: quiz.colors.choiceText,
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                    />
                  ) : null}
                </Group>
              </motion.div>
            ))}
          </Stack>
        )}
        {question.type === "price_is_right" && (
          <>
            <div
              style={{
                position: "relative",
                height: "10vh",
              }}
            >
              <motion.div
                initial="questions"
                animate={animation}
                variants={{
                  questions: { position: "absolute", right: "100vw" },
                  choices: {
                    right: "0vw",
                    transition: { type: "spring", bounce: 0 },
                  },
                  solution: {
                    left: "100vw",
                    transition: { type: "spring", bounce: 0 },
                  },
                }}
                style={{ width: "100%" }}
              >
                <Stack
                  style={{
                    opacity: answerIdx != null ? 0.5 : 1,
                  }}
                >
                  <TextInput
                    type="number"
                    required
                    sx={{ flex: 1 }}
                    placeholder={"Réponse"}
                    disabled={answerIdx != null || localAnswerIdx != null}
                    onChange={(event) => {
                      const value = parseInt(event.target.value)

                      if (isNaN(value)) return

                      setRequestedAnswer(value)
                    }}
                  />
                  <Button
                    variant="white"
                    color={quiz.colors.choiceText}
                    loading={localAnswerIdx != null}
                    disabled={
                      answerIdx != null ||
                      localAnswerIdx != null ||
                      requestedAnswer == null
                    }
                    onClick={() => {
                      if (
                        answerIdx != null ||
                        localAnswerIdx != null ||
                        requestedAnswer == null
                      ) {
                        return
                      }
                      setHasAnswerBeenClicked(true)
                      setLocalAnswerIdx(requestedAnswer)
                      answer(requestedAnswer)
                    }}
                  >
                    Valider
                  </Button>
                </Stack>
              </motion.div>
              <motion.div
                initial="choices"
                animate={animation}
                variants={{
                  choices: { position: "absolute", right: "100vw" },
                  solution: {
                    right: "0vw",
                    transition: { type: "spring", bounce: 0 },
                  },
                }}
                style={{
                  width: "100%",
                  fontSize: "10rem",
                  lineHeight: "5rem",
                  textAlign: "center",
                }}
              >
                {question.solution}
              </motion.div>
            </div>
          </>
        )}
        <motion.div
          initial="choices"
          animate={animation}
          style={{
            scale: 0.5,
            display: "flex",
            justifyContent: "center",
            minHeight: "100dvh",
          }}
        >
          {showTimer && (
            <Timer
              color={quiz.colors.questionText}
              initialDelay={0.5}
              duration={quiz.durations.choices}
            />
          )}
        </motion.div>
      </motion.div>
    </Container>
  )
}
