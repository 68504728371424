import { useCallback, useEffect, useMemo, useState } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { Container, Image, Text } from "@mantine/core"
import { useInterval } from "@mantine/hooks"
import { omit } from "lodash"

import { config } from "config"
import { defaultQuizzes } from "quiz/admin/quiz/quizForm"
import type { Nullable } from "types"
import type {
  AnswerPayload,
  WebsocketMessage,
} from "models/WebsocketMessage.model"
import type { Game, GameState } from "quiz/types/game.model"
import type { Player } from "quiz/types/player.model"
import type { Question, QuestionStatus } from "quiz/types/quiz.model"
import type { SavedGame } from "quiz/types/type"
import type { SavedEvent } from "event/types/types"

import Choices from "quiz/play/game/Choices"
import { PlayLoader } from "quiz/play/components/PlayLoader"
import { QuizRegisterForm } from "quiz/play/signup/QuizRegisterForm"
import { PlayerFormFields } from "shared/form/PlayerTypes"

import {
  QUIZ_GAME_NOT_CONNECTED,
  QUIZ_GAME_WAITING_START,
  QUIZ_GAME_OVER,
} from "_constants"

export type WSStepName = "register" | "connecting" | "running"

const PlayGameView = ({
  eventId,
  gameId,
  game,
  gameState,
  setGameState,
  playerEventData,
  savedEvent,
  savedGame,
  setSavedGame,
}: {
  eventId?: string
  gameId?: string
  game?: Game
  gameState?: GameState
  setGameState: React.Dispatch<React.SetStateAction<GameState | undefined>>
  playerEventData?: Player
  savedEvent?: SavedEvent
  savedGame?: SavedGame
  setSavedGame: (
    playerId: string,
    partitionId: string,
    playerInfo: Omit<Partial<PlayerFormFields>, "termsOfService">
  ) => void
}) => {
  const [playerInfo, setPlayerInfo] = useState<
    Omit<Partial<PlayerFormFields>, "termsOfService"> | undefined
  >() // set only when registered

  const [quizPlayerId, setQuizPlayerId] = useState<string | undefined>()
  const [quizPartitionId, setQuizPartitionId] = useState<string | undefined>()

  useEffect(() => {
    if (savedGame) {
      const { playerId, partitionId } = savedGame
      setQuizPlayerId(playerId)
      setQuizPartitionId(partitionId)
    }
  }, [savedGame])

  const [wsStep, setWsStep] = useState<WSStepName>("register")

  const [questionStatus, setQuestionStatus] = useState<
    QuestionStatus | undefined
  >()

  const [solutionIdx, setSolutionIdx] = useState<Nullable<number>>()
  const [answerIdx, setAnswerIdx] = useState<Nullable<number>>()

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<WebsocketMessage>(config.wsApiRoot, {
      retryOnError: true,
      shouldReconnect: (closeEvent) => true,
      reconnectInterval: 250,
    })

  /* For debug */
  /*const connectionStatus = {
    [ReadyState.CONNECTING]: "ReadyState.CONNECTING",
    [ReadyState.OPEN]: "ReadyState.OPEN",
    [ReadyState.CLOSING]: "ReadyState.CLOSING",
    [ReadyState.CLOSED]: "ReadyState.CLOSED",
    [ReadyState.UNINSTANTIATED]: "ReadyState.UNINSTANTIATED",
  }[readyState]
  */
  const sendPingMessage = useCallback(() => {
    sendJsonMessage(
      {
        action: "ping",
        payload: {
          eventId,
          gameId,
          partitionId: quizPartitionId,
          playerId: quizPlayerId,
        },
      },
      false
    )
  }, [gameId, quizPartitionId, quizPlayerId, eventId, sendJsonMessage])

  // Regular ping to avoid timeout
  const pingInterval = useInterval(() => {
    sendPingMessage()
  }, 5 * 1000)

  const styles = useMemo(
    () =>
      game && game.quizSnapshot
        ? {
            text: {
              color: game.quizSnapshot.colors.questionText,
            },
          }
        : {
            text: {
              color: "#ffffff",
            },
          },
    [game]
  )

  useEffect(() => {
    sendPingMessage()
    pingInterval.start()
    return pingInterval.stop()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Reconnect on startup
  useEffect(() => {
    if (wsStep === "register" && savedGame) {
      const { playerId, partitionId } = savedGame
      sendJsonMessage({
        action: "reconnect",
        payload: { partitionId, playerId },
      })
      setWsStep("connecting")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendJsonMessage, wsStep, savedEvent, savedGame])

  // Force reconnect on mobile
  useEffect(() => {
    if (/iPad|iPhone|iPod|Android|Linux/.test(navigator.platform)) {
      const reload = () => window.location.reload()
      window.addEventListener("focus", reload)
      return () => window.removeEventListener("focus", reload)
    }
  }, [])

  // Handle incoming messages
  useEffect(() => {
    if (lastJsonMessage && game) {
      const { type, payload } = lastJsonMessage
      if (payload && type === "pong") {
        setGameState(payload)
      } else if (type === "registered" && playerInfo) {
        setSavedGame(payload.id, payload.partitionId, playerInfo)
        setWsStep("running")
      } else if (type === "reconnected") {
        setWsStep("running")
      } else if (type === "answered" && payload.questionStatus) {
        setAnswerIdx(payload.answerIdx)
        setQuestionStatus(payload.questionStatus)
      } else if (type === "state") {
        setGameState({ ...gameState, ...payload })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game, lastJsonMessage, playerInfo])

  useEffect(() => {
    if (game && gameState) {
      if (
        gameState.step === "QUESTION" &&
        gameState.questionStatus === "OPENED"
      ) {
        setWsStep("running")
        setQuestionStatus("OPENED")
        setAnswerIdx(null)
        setSolutionIdx(null)
      } else if (
        gameState.step === "QUESTION" &&
        gameState.questionStatus === "STARTED"
      ) {
        setWsStep("running")
        setQuestionStatus("STARTED")
      } else if (
        gameState.step === "QUESTION" &&
        gameState.questionStatus === "CLOSED"
      ) {
        setWsStep("running")
        setQuestionStatus("CLOSED")
        const question = game.quizSnapshot.questions![gameState.questionIdx!]
        switch (question.type) {
          case "multiple_choice":
          case undefined:
            setSolutionIdx(question.solutionIdx)
            break
        }
      } else if (gameState.step === "RESULTS") {
        pingInterval.stop()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, gameState?.step, gameState?.questionStatus, game])

  const register = useCallback(
    (values: Partial<PlayerFormFields>) => {
      const cleanValues: Omit<
        Partial<PlayerFormFields>,
        "termsOfService"
      > = omit(values, "termsOfService")
      const currentUserGameInfo = {
        gameId,
        playerId: quizPlayerId,
        partitionId: quizPartitionId,
      }
      let payload = {
        ...cleanValues,
      }
      if (playerEventData) {
        const { firstName, lastName, email, phoneNumber } = playerEventData
        payload = { ...payload, firstName, lastName, email, phoneNumber }
      }
      payload = { ...payload, ...currentUserGameInfo }
      sendJsonMessage({
        action: "register",
        payload,
      })
      setWsStep("connecting")
      setPlayerInfo(payload)
    },
    [sendJsonMessage, gameId, quizPlayerId, quizPartitionId, playerEventData]
  )

  const answer = useCallback(
    (answerIdx: number) => {
      if (
        gameId &&
        quizPartitionId &&
        quizPlayerId &&
        gameState &&
        gameState.questionIdx !== undefined
      ) {
        const answerPayload: AnswerPayload = {
          gameId,
          partitionId: quizPartitionId,
          playerId: quizPlayerId,
          questionIdx: gameState.questionIdx,
          answerIdx,
        }

        sendJsonMessage({
          action: "answer",
          payload: answerPayload,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sendJsonMessage,
      gameId,
      quizPlayerId,
      quizPartitionId,
      gameState?.questionIdx,
    ]
  )

  const displayedEndingMsg = useMemo(() => {
    if (game?.quizSnapshot.endingMobileMsg) {
      return { __html: game.quizSnapshot.endingMobileMsg.trim() }
    }

    return { __html: "" }
  }, [game])

  const displayedWinnerMsg = useMemo(() => {
    if (game?.quizSnapshot.winnerMsg) {
      return { __html: game.quizSnapshot.winnerMsg.trim() }
    }

    return { __html: "" }
  }, [game])

  const questionToDisplay = useMemo<Question | undefined>(() => {
    if (
      game &&
      gameState &&
      gameState.step === "QUESTION" &&
      typeof gameState.questionIdx === "number"
    ) {
      return game.quizSnapshot.questions[gameState.questionIdx]
    }
  }, [game, gameState])

  return (
    <>
      {!savedEvent && !game && !savedGame && !gameState ? (
        <Container pb={"lg"}>
          <PlayLoader
            message={
              <Text
                className="text-2xl text-center font-team-a-bold"
                sx={styles.text}
                dangerouslySetInnerHTML={{ __html: QUIZ_GAME_NOT_CONNECTED }}
              />
            }
          />
        </Container>
      ) : null}

      {readyState === ReadyState.CONNECTING ||
      wsStep === "connecting" ||
      (savedGame &&
        game &&
        game.state.step === "QUESTION" &&
        !gameState &&
        wsStep === "running") ? (
        <Container pb={"lg"}>
          <PlayLoader message="Connexion en cours..." />
        </Container>
      ) : null}

      {!savedGame &&
      game &&
      game.state.step !== "RESULTS" &&
      (!gameState || gameState.step !== "RESULTS") &&
      readyState === ReadyState.OPEN ? (
        <QuizRegisterForm onSubmit={register} quiz={game!.quizSnapshot} />
      ) : null}

      {((gameState?.step === "CREATED" ||
        (game && game.state.step === "CREATED" && !gameState)) &&
        wsStep === "running" &&
        readyState === ReadyState.OPEN) ||
      (savedEvent &&
        !game &&
        !gameState &&
        !savedGame &&
        wsStep === "register" &&
        readyState === ReadyState.OPEN) ? (
        <Container pb={"lg"}>
          <PlayLoader
            icon={
              <Image
                src={
                  game?.quizSnapshot.images.waitingLogo ||
                  defaultQuizzes["16_9"].images.waitingLogo
                }
                alt="Waiting logo"
                width={150}
                style={{ paddingBottom: "10px" }}
              />
            }
            message={
              <Text
                className="text-xl text-center font-team-a-bold pb-2.5"
                sx={styles.text}
                dangerouslySetInnerHTML={{ __html: QUIZ_GAME_WAITING_START }}
              />
            }
          />
        </Container>
      ) : null}

      {gameState &&
      gameState.step === "RESULTS" &&
      gameState?.leaderboard?.[0]?.id === quizPlayerId &&
      readyState === ReadyState.OPEN ? (
        <Container pb={"lg"}>
          <PlayLoader
            icon={
              <Image
                src={
                  game?.quizSnapshot.images.gameOverLogo ||
                  defaultQuizzes["16_9"].images.gameOverLogo
                }
                width={150}
                style={{ paddingBottom: "10px" }}
                alt="Game Over logo"
              />
            }
            message={
              <>
                <Text
                  className="uppercase text-xl text-center font-team-a-bold"
                  sx={styles.text}
                  dangerouslySetInnerHTML={displayedWinnerMsg}
                />
                <Image
                  src={
                    game?.quizSnapshot.images.winnerLogo ||
                    defaultQuizzes["16_9"].images.winnerLogo
                  }
                  width={65}
                  alt="Winner logo"
                />
              </>
            }
          />
        </Container>
      ) : null}

      {(game &&
        game.state.step === "RESULTS" &&
        readyState === ReadyState.OPEN &&
        !gameState) ||
      (gameState &&
        gameState.step === "RESULTS" &&
        gameState.leaderboard?.[0]?.id !== quizPlayerId) ? (
        <Container pb={"lg"}>
          <PlayLoader
            icon={
              <Image
                src={
                  game?.quizSnapshot.images.gameOverLogo ||
                  defaultQuizzes["16_9"].images.gameOverLogo
                }
                width={150}
                style={{ paddingBottom: "10px" }}
                alt="Game Over logo"
              />
            }
            message={
              <Text
                className="uppercase w-10/12 pt-1.5 text-xl text-center font-team-a-bold"
                sx={styles.text}
                dangerouslySetInnerHTML={displayedEndingMsg}
              />
            }
          />
        </Container>
      ) : null}
      {savedGame && game && gameState && wsStep === "running" ? (
        <Container pb={"lg"}>
          {gameState.step === "GAME_OVER" || game.state.step === "GAME_OVER" ? (
            <PlayLoader
              icon={
                <Image
                  src={
                    game?.quizSnapshot.images.gameOverLogo ||
                    defaultQuizzes["16_9"].images.gameOverLogo
                  }
                  alt="Game Over Logo"
                  width={150}
                  style={{ paddingBottom: "10px" }}
                />
              }
              message={
                <Text
                  className="text-xl text-center pb-2.5"
                  sx={styles.text}
                  dangerouslySetInnerHTML={{ __html: QUIZ_GAME_OVER }}
                />
              }
            />
          ) : null}
          {gameState.step === "QUESTION" &&
          readyState === ReadyState.OPEN &&
          questionToDisplay &&
          questionStatus ? (
            <Choices
              questionStatus={questionStatus}
              question={questionToDisplay}
              quiz={game!.quizSnapshot}
              answer={answer}
              answerIdx={answerIdx}
              solutionIdx={solutionIdx}
            />
          ) : null}
        </Container>
      ) : null}

      {/*<>
        <pre>{`questionToDisplay : ${JSON.stringify(questionToDisplay)}`}</pre>
        <pre>{`questionStatus: ${questionStatus}`}</pre>
        <pre>{`question : ${JSON.stringify(
          game?.quizSnapshot?.questions[gameState?.questionIdx!]
        )}`}</pre>
        <pre>{`duration: ${JSON.stringify(
          game?.quizSnapshot?.durations
        )}`}</pre>
        <pre>{`game step: ${JSON.stringify(game?.state.step, null, 2)}`}</pre>
        <pre>{`gameState: ${JSON.stringify(gameState)}`}</pre>
        <pre>{`savedGame: ${JSON.stringify(savedGame)}`}</pre>
        <pre>{`savedEvent: ${JSON.stringify(savedEvent)}`}</pre>
        <pre>{`lastJsonMessage: ${JSON.stringify(lastJsonMessage)}`}</pre>
        <pre>{`WsStep: ${wsStep}`}</pre>
        <pre>{`solutionIdx: ${solutionIdx}`}</pre>
        <pre>{`answerIdx: ${answerIdx}`}</pre>
        <pre>{`connectionStatus: ${connectionStatus}`}</pre>
      </>*/}
    </>
  )
}

export { PlayGameView }
