import { UseFormReturnType } from "@mantine/form";
import { Group, NumberInput } from "@mantine/core";
import type { Quiz } from "quiz/types/quiz.model";

const fields = [
  ["question", "Questions"],
  ["choices", "Proposition"],
  ["solution", "Réponse"],
  ["winner", "Nom du gagnant"],
] as [keyof Quiz["durations"], string][];

export default function QuizDurationsForm({
  form,
}: {
  form: UseFormReturnType<Omit<Quiz, "id">>;
}) {
  return (
    <Group>
      {fields.map(([name, label]) => (
        <NumberInput
          key={name}
          required
          sx={{ flex: "1 1 0" }}
          type={"number"}
          rightSectionWidth={100}
          rightSection={<span>secondes</span>}
          label={label}
          {...form.getInputProps(`durations.${name}`)}
        />
      ))}
    </Group>
  );
}
