import { useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Group, Stack, Text, TextInput, Title } from "@mantine/core"
import { ExternalLink } from "tabler-icons-react"

import { config, ScreenType } from "config"
import Collapsable from "../../../common/admin/ui/Collapsable"
import { gameService } from "../../../services/services"
import GamePlayers from "./GamePlayers"
import GameStateLabel from "./GameStateLabel"
import useQuiz from "../quiz/useQuiz"
import QueryWrapper from "../../../shared/components/QueryWrapper"
import { useGetGameQuery } from "../api/games.endpoints"
import { QuizBreadcrumbs } from "../components/QuizBreadcrumbs"
import { openQrCodeModal } from "../../../common/admin/ui/openQrCodeModal"
import { getQuizPlayUrl } from "../../../common/utils/routeHelpers"
import { openWindow } from "../../../common/utils/openWindow"

export default function GameView() {
  const quiz = useQuiz()!
  const { gameId } = useParams()
  const getGameQuery = useGetGameQuery(gameId!)

  const [numPlayers, setNumPlayers] = useState<number>()

  const [starting, setStarting] = useState(false)

  const navigate = useNavigate()

  const startGame = useCallback(() => {
    setStarting(true)
    gameService.start(getGameQuery.data!.id).then(getGameQuery.refetch)
  }, [getGameQuery])

  const deleteGame = useCallback(
    () =>
      gameService.delete(getGameQuery.data!.id).then(() => navigate(`./../..`)),
    [getGameQuery, navigate]
  )

  const screenType = useMemo<ScreenType | undefined>(
    () => (quiz ? quiz.screenType : undefined),
    [quiz]
  )
  const displayedWidth = useMemo<number | undefined>(
    () => (screenType ? config.screenSizes[screenType][0] : undefined),
    [screenType]
  )
  const displayedHeight = useMemo<number | undefined>(
    () => (screenType ? config.screenSizes[screenType][1] : undefined),
    [screenType]
  )
  return (
    <QueryWrapper query={getGameQuery} dimOnRefetch={false}>
      {(game) => (
        <>
          <QuizBreadcrumbs quiz={quiz} game={game} />

          <Title order={3} align={"center"} m={"lg"}>
            {game?.name || `Partie #${game?.id?.split("-")[0]}`}
          </Title>

          <Stack>
            <Collapsable title={"Infos"}>
              <Stack>
                <Group position={"apart"}>
                  <GameStateLabel game={game!} />
                  {game?.state?.step === "CREATED" && (
                    <Button
                      className="bg-button-admin"
                      onClick={startGame}
                      disabled={starting}
                    >
                      Lancer la partie
                    </Button>
                  )}
                </Group>
                <TextInput
                  label={
                    <span className="flex">
                      URL de l'interface mobile (
                      <Text
                        component="span"
                        variant="link"
                        inherit
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          openQrCodeModal(getQuizPlayUrl(game!.id), game.name)
                        }
                      >
                        QR Code
                      </Text>
                      <Text component="span" mx={"xs"}>
                        |
                      </Text>
                      <Text
                        className="flex items-center"
                        component="span"
                        variant="link"
                        inherit
                        onClick={() =>
                          openWindow(
                            `/quiz/${game!.id}`,
                            undefined,
                            undefined,
                            "mobile"
                          )
                        }
                      >
                        <ExternalLink size={16} />
                        Formulaire d'inscription
                      </Text>
                      )
                    </span>
                  }
                  disabled={true}
                  value={`${config.webAppRoot}/quiz/${game!.id}`}
                />

                <TextInput
                  label={
                    <span className="flex">
                      URL de l'interface écran (
                      <Text
                        component="span"
                        variant="link"
                        inherit
                        sx={{ cursor: "pointer" }}
                      >
                        <Text
                          className="flex items-center"
                          onClick={() =>
                            openWindow(
                              `/screen/game/${game?.id}`,
                              displayedWidth,
                              displayedHeight
                            )
                          }
                          component="span"
                          inherit
                        >
                          <ExternalLink size={16} />
                          Ouvrir
                        </Text>
                      </Text>
                      )
                    </span>
                  }
                  disabled={true}
                  value={`${config.webAppRoot}/screen/game/${game!.id}`}
                />
              </Stack>
            </Collapsable>

            {game?.state?.step === "RESULTS" &&
              (() => {
                const leaderboard = game?.state?.leaderboard
                const winner = leaderboard?.[0]

                return (
                  <Collapsable title={"Gagnant"}>
                    <ol>
                      {(winner &&
                        leaderboard.slice(0, 3).map((player, index) => (
                          <li key={index}>
                            {player.firstName} {player.lastName} -{" "}
                            {player.phoneNumber} - tribune/accès:{" "}
                            {player.accessNumber}, rang: {player.rankNumber},
                            place: {player.seatNumber}
                            {game?.quizSnapshot?.mode === "price_is_right" && (
                              <>
                                , réponse:{" "}
                                {player.answers?.[0] === undefined
                                  ? "Aucune"
                                  : player.answers?.[0]}
                              </>
                            )}
                            <br />
                          </li>
                        ))) || <li>Personne</li>}
                    </ol>
                  </Collapsable>
                )
              })()}

            <Collapsable
              title={`Joueurs ${numPlayers ? `(${numPlayers})` : ""}`}
            >
              <GamePlayers onNumPlayersChanged={setNumPlayers} />
            </Collapsable>

            <Group position={"center"} mt={"xl"}>
              <Button className="bg-warning text-white" onClick={deleteGame}>
                Supprimer
              </Button>
            </Group>
          </Stack>
        </>
      )}
    </QueryWrapper>
  )
}
