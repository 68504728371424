import React from "react"
import { useListPronosticsQuery } from "pronostic/api/pronostic.endpoints"
import QueryWrapper from "shared/components/QueryWrapper"

export function PronosticsView() {
  const listPronosticsQuery = useListPronosticsQuery()
  return (
    <>
      <h1 style={{ color: "black" }}>Pronostics</h1>
      <br />
      <QueryWrapper query={listPronosticsQuery}>
        {(pronostics) => <pre>{JSON.stringify(pronostics)}</pre>}
      </QueryWrapper>
    </>
  )
}
