import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import QRCode from "react-qr-code"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { Box, Group, Image, Title } from "@mantine/core"

import type { GameState } from "quiz/types/game.model"
import type { WebsocketMessage } from "models/WebsocketMessage.model"
import { config } from "config"
import { getQuizPlayUrl } from "common/utils/routeHelpers"
import { CoubertinQuestion } from "quiz/screen/cast/coubertin/CoubertinQuestion"
import { ParcDesPrincesQuestion } from "quiz/screen/cast/parc_des_princes/ParcDesPrincesQuestion"
import useGame from "quiz/admin/game/useGame"
import {
  QUIZ_GAME_RESULT_CALCULATION,
  QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG,
  QUIZ_GAME_WAITING_START,
} from "_constants"

export default function RunningGame() {
  const { gameId } = useParams()
  const game = useGame()!

  const [gameState, setGameState] = useState<GameState>(game!.state)
  const url = useMemo(() => getQuizPlayUrl(gameId!), [gameId])

  const question = useMemo(() => {
    if (
      gameState.questionIdx != null &&
      game?.quizSnapshot.questions[gameState.questionIdx]
    ) {
      return game.quizSnapshot.questions[gameState.questionIdx]
    }
  }, [game?.quizSnapshot.questions, gameState.questionIdx])

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<WebsocketMessage>(config.wsApiRoot, {
      retryOnError: true,
      shouldReconnect: (closeEvent) => true,
    })

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        action: "register",
        payload: {
          appName: "screen",
          gameId,
        },
      })
    }
  }, [readyState, game, sendJsonMessage, gameId])

  useEffect(() => {
    if (lastJsonMessage?.type === "state") {
      setGameState(lastJsonMessage.payload)
    }
  }, [lastJsonMessage])

  const quiz = game!.quizSnapshot
  const screenType = quiz.screenType ?? "bandeau_horizontal"
  const Question =
    screenType === "bandeau_horizontal"
      ? CoubertinQuestion
      : ParcDesPrincesQuestion

  const backgroundSize = useMemo(
    () =>
      quiz?.screenType === "bandeau_horizontal"
        ? "1540px 220px"
        : "1920px 1080px",
    [quiz]
  )

  const winner = useMemo<string>(() => {
    if (
      gameState &&
      gameState.leaderboard &&
      gameState.leaderboard.length > 0
    ) {
      return gameState.leaderboard[0].firstName
    }
    return ""
  }, [gameState])

  const winnerMessage = useMemo(() => {
    if (game && winner.length > 0) {
      return {
        __html: game.quizSnapshot.endingMsg.replace("#WINNER#", winner).trim(),
      }
    }
    return { __html: QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG }
  }, [game, winner])

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: game!.quizSnapshot.colors.background,
        backgroundImage: `url(${game!.quizSnapshot.images.screenBackground})`,
        backgroundSize,
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {gameState.step === "QUESTION" && question ? (
        <Question
          question={question}
          key={question.label || "2"}
          quiz={game!.quizSnapshot}
        />
      ) : null}

      {gameState.step === "GAME_OVER" &&
        ((game!.quizSnapshot.screenType === "bandeau_horizontal" && (
          <strong>{QUIZ_GAME_RESULT_CALCULATION}</strong>
        )) ||
          (game!.quizSnapshot.screenType === "16_9" && (
            <Group>
              <Box mx={"xl"}>
                <Image
                  src={quiz.images.gameOverLogo}
                  height={200}
                  alt={"GameOverLogo"}
                />
              </Box>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "3em",
                  minWidth: "40rem",
                }}
              >
                <strong
                  style={{ fontSize: "1.5em", textTransform: "uppercase" }}
                >
                  {QUIZ_GAME_RESULT_CALCULATION}
                </strong>
              </h1>
            </Group>
          )))}

      {gameState.step === "RESULTS" && winner.length > 0 && winnerMessage ? (
        <Group>
          <Box mx={"xl"}>
            <Image
              src={game?.quizSnapshot!.images.gameOverLogo}
              height={200}
              alt={"Game Over logo"}
            />
          </Box>
          <h1
            style={{ textAlign: "center", fontSize: "2em", minWidth: "40rem" }}
            dangerouslySetInnerHTML={winnerMessage}
          />
        </Group>
      ) : null}

      {gameState.step === "CREATED" && (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {(game!.quizSnapshot.screenType === "bandeau_horizontal" && (
            <>
              <div style={{ background: "white", padding: "8px" }}>
                <QRCode
                  size={200}
                  fgColor={game!.quizSnapshot.colors.background}
                  bgColor={game!.quizSnapshot.colors.questionText}
                  value={url}
                />
              </div>

              <Title
                sx={{
                  color: game!.quizSnapshot.colors.questionText,
                  fontFamily: "inherit",
                }}
              >
                {QUIZ_GAME_WAITING_START}
              </Title>
            </>
          )) ||
            (game!.quizSnapshot.screenType === "16_9" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8rem",
                  }}
                >
                  <Image
                    src={quiz.images.waitingLogo}
                    style={{
                      width: "40vw",
                    }}
                    alt="Waiting Logo"
                  />
                  <Title
                    sx={{
                      color: game!.quizSnapshot.colors.questionText,
                      fontFamily: "inherit",
                      textTransform: "uppercase",
                      fontSize: "5em",
                    }}
                  >
                    {QUIZ_GAME_WAITING_START}
                  </Title>
                </Box>

                <div style={{ background: "white", padding: "16px" }}>
                  <QRCode
                    size={600}
                    fgColor={game!.quizSnapshot.colors.background}
                    bgColor={game!.quizSnapshot.colors.questionText}
                    value={url}
                  />
                </div>
              </>
            ))}
        </Box>
      )}
    </Box>
  )
}
