import React from "react"

import EntityToolbar from "common/admin/EntityToolbar"
import { useEvent } from "event/hooks/useEvent"
import { useEventFormContext } from "event/admin/context/form-context"

import {
  useAddEventMutation,
  useRemoveEventMutation,
  useUpdateEventMutation,
} from "event/api/event.api"

function EventToolbar() {
  const event = useEvent()
  const form = useEventFormContext()

  const addEventMutation = useAddEventMutation()
  const updateEventMutation = useUpdateEventMutation()
  const removeEventMutation = useRemoveEventMutation()

  return (
    <EntityToolbar
      entity={event}
      form={form}
      addMutation={addEventMutation}
      updateMutation={updateEventMutation}
      removeMutation={removeEventMutation}
      label="événement"
    />
  )
}

export default EventToolbar
