import { useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Box, NavLink } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import type { Message } from "wall/types/message.model"
import type { Event } from "event/types/event.model"
import {
  NAV_TITLE,
  NAV_PRONOS,
  NAV_SELFIES_MSG,
  NAV_QUESTIONS,
  NAV_QUIZ,
  // NAV_BLINDTEST,
  // NAV_GOLDEN_BAR,
  // NAV_BINGO,
  NAV_MAN_MATCH,
  NAV_BUVETTE,
} from "_constants"
import "./PSGListNavigation.css"
import psgLogo from "./parc-des-prince-logo.png"

interface PSGNavigationProps {
  event: Event
  form?: UseFormReturnType<
    Partial<Message>,
    (values: Partial<Message>) => Partial<Message>
  >
  setIsMessageSent?: React.Dispatch<React.SetStateAction<boolean>>
}

type Nav =
  | "clickandcollect"
  | "default"
  | "motm"
  | "questions"
  | "prono"
  | "quiz"
  | "wall"
interface State {
  event: Event
  nav: Nav
}

export function PSGListNavigation({
  event,
  form,
  setIsMessageSent,
}: PSGNavigationProps) {
  const location = useLocation()
  const { state } = location

  const [isNavOpen, setIsNavOpen] = useState(false)

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen)
  }

  const navPrefix = useMemo(() => `/event/${event.id}`, [event])

  function getNavTitle(type: Nav) {
    let fn
    const navs = {
      prono: () => NAV_PRONOS,
      wall: () => NAV_SELFIES_MSG,
      questions: () => NAV_QUESTIONS,
      quiz: () => NAV_QUIZ,
      motm: () => NAV_MAN_MATCH,
      clickandcollect: () => NAV_BUVETTE,
      default: () => NAV_TITLE,
    }

    if (navs[type]) {
      fn = navs[type]
    } else {
      fn = navs["default"]
    }

    return fn()
  }

  const currentNav: Nav = useMemo(
    () => (state as State)?.nav as Nav | "default",
    [state]
  )

  return (
    <Box className="nav-wrapper">
      <NavLink
        label={<div className="nav-title">{getNavTitle(currentNav)}</div>}
        icon={
          <img
            src={psgLogo}
            alt="parc des princes logo"
            style={{ width: 60, height: 25 }}
          />
        }
        opened={isNavOpen}
        onClick={() => toggleNav()}
      >
        {event.pronostics_display && currentNav !== "prono" ? (
          <NavLink
            component={Link}
            label={<div className="nav-item">{NAV_PRONOS}</div>}
            state={{ event, nav: "prono" }}
            to={`${navPrefix}/prono`}
          />
        ) : null}
        {event.selfies_display && currentNav !== "wall" ? (
          <NavLink
            component={Link}
            label={<div className="nav-item">{NAV_SELFIES_MSG}</div>}
            onClick={() => {
              if (setIsMessageSent && form) {
                setIsMessageSent(false)
                form.setFieldValue("message", "")
              }
              toggleNav()
            }}
            state={{ event, nav: "wall" }}
            to={
              event.wall_id
                ? `${navPrefix}/wall/${event.wall_id}`
                : `${navPrefix}/wall`
            }
          />
        ) : null}
        {event.questions_display && currentNav !== "questions" ? (
          <NavLink
            component={Link}
            label={<div className="nav-item">{NAV_QUESTIONS}</div>}
            onClick={() => {
              if (setIsMessageSent && form) {
                setIsMessageSent(false)
                form.setFieldValue("message", "")
              }
              toggleNav()
            }}
            state={{ event, nav: "questions" }}
            to={
              event.wall_id
                ? `${navPrefix}/wall/${event.wall_questions_id}`
                : `${navPrefix}/wall`
            }
          />
        ) : null}
        {event.quiz_display && currentNav !== "quiz" ? (
          <NavLink
            component={Link}
            label={<div className="nav-item">{NAV_QUIZ}</div>}
            state={{ event, nav: "quiz" }}
            to={
              event.quiz_id
                ? `${navPrefix}/quiz/${event.quiz_id}`
                : `${navPrefix}/quiz`
            }
          />
        ) : null}

        {/*
        <NavLink
          component={Link}
          label={<div className="nav-item">{NAV_BLINDTEST}</div>}
          state={{ event, nav: "blindtest" }}
          to={`${navPrefix}/blindtest`}
        />
        <NavLink
          component={Link}
          label={<div className="nav-item">{NAV_GOLDEN_BAR}</div>}
          state={{ event, nav: "goldenbar" }}
          to={`${navPrefix}/goldenbar`}
        />
        <NavLink
          component={Link}
          label={<div className="nav-item">{NAV_BINGO}</div>}
          state={{ event, nav: "bingo" }}
          to={`${navPrefix}/bingo`}
        />
*/}
        {event.man_of_the_match_display && currentNav !== "motm" ? (
          <NavLink
            component={Link}
            label={<div className="nav-item">{NAV_MAN_MATCH}</div>}
            state={{ event, nav: "motm" }}
            to={`${navPrefix}/motm`}
          />
        ) : null}
        {event.food_menu_display && currentNav !== "clickandcollect" ? (
          <NavLink
            component={Link}
            label={<div className="nav-item">{NAV_BUVETTE}</div>}
            state={{ event, nav: "clickandcollect" }}
            to={`${navPrefix}/clickandcollect`}
          />
        ) : null}
      </NavLink>
    </Box>
  )
}
