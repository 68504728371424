import { Route, Routes } from "react-router-dom"

import { ClickAndCollectPDFPresenter } from "click-and-collect/components/ClickAndCollectPDFPresenter"
import { EventPlayThemeWrapper } from "event/play/components/EventPlayThemeWrapper"
import { EventHomepageView } from "event/play/pages/EventHomepageView"
import { PronosticView } from "event/play/pages/PronosticView"
import ManOfTheMatchView from "event/play/pages/ManOfTheMatchView"
import { WallPlayRoutes } from "wall/play/routes/WallPlayRoutes"
import { QuizGamePlayRoutes } from "quiz/play/routes/QuizGamePlayRoutes"

export function EventPlayRoutes() {
  return (
    <Routes>
      <Route path=":eventId" element={<EventPlayThemeWrapper />}>
        <Route path="" element={<EventHomepageView />} />
        <Route path="prono" element={<PronosticView />} />
        <Route path="motm" element={<ManOfTheMatchView />} />
        <Route
          path="clickandcollect"
          element={<ClickAndCollectPDFPresenter />}
        />
        <Route path="wall/*" element={<WallPlayRoutes />} />
        <Route path="quiz/*" element={<QuizGamePlayRoutes />} />
      </Route>
    </Routes>
  )
}
