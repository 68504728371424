import { useLocalStorage } from "@mantine/hooks"
import { useMemo } from "react"

import type { SavedEvent } from "event/types/types"
import type { PlayerFormFields } from "shared/form/PlayerTypes"

export function useSavedEvent(eventId: string) {
  const [savedEvents, setSavedEvents] = useLocalStorage<
    Record<string, SavedEvent>
  >({
    key: "events",
    getInitialValueInEffect: false,
  })

  return useMemo(
    () =>
      [
        (savedEvents || {})[eventId],
        (event: SavedEvent, playerInfo?: Partial<PlayerFormFields>) =>
          setSavedEvents((events) => {
            const { playerId, partitionId, accessToken, pogVote } = event
            return {
              ...events,
              [eventId]: {
                accessToken,
                partitionId,
                playerInfo,
                playerId,
                pogVote,
              },
            }
          }),
      ] as const,
    [savedEvents, setSavedEvents, eventId]
  )
}
