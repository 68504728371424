import { Group, Text } from "@mantine/core"
import { LayoutList } from "tabler-icons-react"

import {
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
} from "_roles"
import { AccessRights } from "common/admin/auth/AccessRights"
import { Quiz } from "quiz/types/quiz.model"

type QuizzesListTableRowProps = {
  quiz: Quiz
  onClick: () => void
}
const QuizzesListTableRow = ({ quiz, onClick }: QuizzesListTableRowProps) => {
  const { creationTime, id, name, organization, questions } = quiz
  return (
    <tr key={id} onClick={onClick} className="table-row cursor-pointer">
      <td>
        <Text variant="link">{name || `Quiz #${id.split("-")[0]}`}</Text>
      </td>
      <AccessRights
        authorizedRoles={QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES}
        authorizedOrganizations={
          QUIZ_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
        }
      >
        <td>{organization}</td>
      </AccessRights>
      <td>
        <Group>
          <LayoutList />{" "}
          <span>
            <strong>{questions?.length || 0}</strong> questions
          </span>
        </Group>
      </td>
      <td>
        <Text>
          {new Date(creationTime).toLocaleString("fr-FR", {
            timeZone: "UTC",
          })}
        </Text>
      </td>
    </tr>
  )
}

export { QuizzesListTableRow }
