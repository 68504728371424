const NAV_TITLE = "Ici c'est Paris"
const NAV_PRONOS = "Pronos"
const NAV_SELFIES_MSG = "Selfies et messages"
const NAV_QUESTIONS = "Questions"
const NAV_QUIZ = "Quiz mi-temps"
const NAV_BLINDTEST = "Blindtest"
const NAV_GOLDEN_BAR = "Golden barre"
const NAV_BINGO = "Bingo Paris"
const NAV_MAN_MATCH = "Homme du match"
const NAV_BUVETTE = "Menu buvette"

const EVENT_CONTACT_FORM_CHECKBOX_LINK = "Disponible ici."
const EVENT_CONTACT_FORM_CHECKBOX_MESSAGE =
  "J'accepte le règlement du Paris St-Germain"
const EVENT_CONTACT_FORM_THANKS =
  "Le Paris Saint-Germain Handball <br /> vous remercie pour votre soutien et votre fidélité."
const EVENT_CONTACT_FORM_VALIDATION_BUTTON = "Valider"
const EVENT_CONTACT_FORM_WELCOME =
  "Cher supporter bienvenue ! <br/> Pour profiter de l'ensemble de l'expérience, <br/> veuillez remplir le formulaire ci-joint."
const EVENT_HEADER_FIRST_OPPONENT = "PARIS SAINT-GERMAIN"
const EVENT_LANDING_MSG =
  "<span>PROFITEZ DE LA FAN EXPÉRIENCE</span><span>DU PARIS SAINT-GERMAIN HANDBALL</span><span>AVANT, PENDANT ET APRÈS LE MATCH !</span>"
const EVENT_MENU_CLICK_AND_COLLECT = "MENU BUVETTE"
const EVENT_MENU_KICKOFF = "KICK OFF"
const EVENT_MENU_MAN_OF_THE_MATCH = "VOTEZ POUR L'HOMME DU MATCH"
const EVENT_MENU_MESSAGES = "ENCOURAGEZ <br /> VOS <br /> JOUEURS"
const EVENT_MENU_QUIZ = "JOUEZ <br /> AU QUIZ <br /> MI-TEMPS"
const EVENT_MENU_QUESTIONS = "QUESTIONS INVITES"
const EVENT_MENU_PRONOSTICS = "FAITES VOS PRONOSTICS"

const FORM_VALIDATION_FIRST_NAME_REQUIRED = "Prénom requis."
const FORM_VALIDATION_LAST_NAME_REQUIRED = "Nom requis."
const FORM_VALIDATION_EMAIL_NOT_VALIDATED = "Email invalide."
const FORM_VALIDATION_PHONE_NOT_VALIDATED = "Numéro de téléphone valide requis."
const FORM_VALIDATION_TERMS_OF_SERVICE =
  "Vous devez accepter le règlement général pour continuer."

const MAN_OF_THE_MATCH_ALREADY_VOTED =
  "Merci d'avoir voté ! Revenez une fois le vote terminé pour voir le résultat."
const MAN_OF_THE_MATCH_IN_PROGESS = "Désignez votre homme du match !"
const MAN_OF_THE_MATCH_NOT_STARTED =
  "Il n'est pas encore possible de voter pour l'homme du match."

const QUIZ_ADMIN_MISSING_WINNER_TEMPLATE =
  "Chaîne de caractère #WINNER# manquante"
const QUIZ_ADMIN_WRONG_QUESTION_FORMAT =
  "Réponse invalide, doit être un nombre entier."

const QUIZ_GAME_DEFAULT_ENDING_MOBILE_MSG =
  "Le quiz est terminé, merci d'avoir participé !"
const QUIZ_GAME_DEFAULT_WINNER_PRIVATE_MSG =
  "Félicitations ! Vous allez être contacté par l'équipe organisatrice"
const QUIZ_GAME_DEFAULT_ENDING_SCREEN_MSG =
  "Le quiz est terminé !<br> C'est #WINNER# qui le remporte !"
const QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG =
  "Le vainqueur va être contacté par équipe organisatrice"
const QUIZ_GAME_NOT_CONNECTED =
  "LE QUIZ EST RÉSERVÉ AUX <br/> SPECTATEURS QUI SONT PRÉSENTS <br/> EN TRIBUNE LE JOUR DE LA RENCONTRE."
const QUIZ_GAME_OVER = "Le quiz est terminé !<br/> En attente des résultats."
const QUIZ_GAME_RESULT_CALCULATION = "Calcul des résultats en cours"
const QUIZ_GAME_WAITING_START = "Le quiz va bientôt commencer."
const WALL_BUTTON = "Rafraîchir"
const WALL_WAITING_MESSAGE = "L'envoi des messages n'est pas encore ouvert"
const WALL_QUESTIONS_WAITING_MESSAGE =
  "L'envoi des questions est terminé. Profitez du match !"
export {
  NAV_TITLE,
  NAV_PRONOS,
  NAV_SELFIES_MSG,
  NAV_QUESTIONS,
  NAV_QUIZ,
  NAV_BLINDTEST,
  NAV_GOLDEN_BAR,
  NAV_BINGO,
  NAV_MAN_MATCH,
  NAV_BUVETTE,
  EVENT_CONTACT_FORM_CHECKBOX_LINK,
  EVENT_CONTACT_FORM_CHECKBOX_MESSAGE,
  EVENT_CONTACT_FORM_THANKS,
  EVENT_CONTACT_FORM_VALIDATION_BUTTON,
  EVENT_CONTACT_FORM_WELCOME,
  EVENT_HEADER_FIRST_OPPONENT,
  EVENT_LANDING_MSG,
  EVENT_MENU_CLICK_AND_COLLECT,
  EVENT_MENU_KICKOFF,
  EVENT_MENU_MAN_OF_THE_MATCH,
  EVENT_MENU_MESSAGES,
  EVENT_MENU_PRONOSTICS,
  EVENT_MENU_QUESTIONS,
  EVENT_MENU_QUIZ,
  FORM_VALIDATION_FIRST_NAME_REQUIRED,
  FORM_VALIDATION_LAST_NAME_REQUIRED,
  FORM_VALIDATION_EMAIL_NOT_VALIDATED,
  FORM_VALIDATION_PHONE_NOT_VALIDATED,
  FORM_VALIDATION_TERMS_OF_SERVICE,
  MAN_OF_THE_MATCH_ALREADY_VOTED,
  MAN_OF_THE_MATCH_IN_PROGESS,
  MAN_OF_THE_MATCH_NOT_STARTED,
  QUIZ_ADMIN_MISSING_WINNER_TEMPLATE,
  QUIZ_ADMIN_WRONG_QUESTION_FORMAT,
  QUIZ_GAME_DEFAULT_ENDING_MOBILE_MSG,
  QUIZ_GAME_DEFAULT_WINNER_PRIVATE_MSG,
  QUIZ_GAME_DEFAULT_ENDING_SCREEN_MSG,
  QUIZ_GAME_SCREEN_DEFAULT_WINNER_MSG,
  QUIZ_GAME_NOT_CONNECTED,
  QUIZ_GAME_OVER,
  QUIZ_GAME_RESULT_CALCULATION,
  QUIZ_GAME_WAITING_START,
  WALL_BUTTON,
  WALL_WAITING_MESSAGE,
  WALL_QUESTIONS_WAITING_MESSAGE,
}
