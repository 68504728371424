import { Route, Routes } from "react-router-dom"
import { QuizScreenShellWithGameWrapper } from "./QuizScreenShellWithGameWrapper"
import RunningGame from "./cast/RunningGame"

export function QuizScreenRoutes() {
  return (
    <Routes>
      <Route path=":gameId" element={<QuizScreenShellWithGameWrapper />}>
        <Route path="" element={<RunningGame />} />
      </Route>
    </Routes>
  )
}
