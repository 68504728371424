import { Route, Routes } from "react-router-dom"

import { WallPlayThemeWrapper } from "wall/play/layouts/WallPlayThemeWrapper"
import { PostMessagePresenter } from "wall/play/views/PostMessagePresenter"
import { DefaultPostMessageView } from "wall/play/views/DefaultPostMessageView"

export function WallPlayRoutes() {
  return (
    <Routes>
      <Route path="" element={<DefaultPostMessageView />} />
      <Route path=":wallId" element={<WallPlayThemeWrapper />}>
        <Route path="" element={<PostMessagePresenter />}></Route>
      </Route>
    </Routes>
  )
}
