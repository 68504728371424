import { apiSlice } from "../../core/api";
import { makeCrudEndpoints } from "../../shared/utils/api/apiHelpers";
import { Workforce } from "./workforce.model";

const api = apiSlice.injectEndpoints({
  endpoints: makeCrudEndpoints<"Workforce", Workforce>("Workforce", "workforces")
});

export const {
  useGetWorkforceQuery,
  useListWorkforcesQuery,
  useAddWorkforceMutation,
  useRemoveWorkforceMutation,
  useUpdateWorkforceMutation,
} = api;
