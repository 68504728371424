import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Clock } from "tabler-icons-react"
import { v4 as uuidv4 } from "uuid"
import { Box, Button, Group, Stack, Table, Text, Title } from "@mantine/core"

import {
  ADMIN_ORGANIZATION,
  EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES,
  EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS,
  EVENT_CREATE_AUTHORISED_ROLES,
  EVENT_CREATE_AUTHORISED_ORGANIZATIONS,
} from "_roles"
import type { Event } from "event/types/event.model"
import Collapsable from "common/admin/ui/Collapsable"
import { AccessRights } from "common/admin/auth/AccessRights"
import { useOrganization } from "common/admin/auth/hooks"
import QueryWrapper from "shared/components/QueryWrapper"
import { Th } from "shared/components/table/Th"
import EventBreadcrumbs from "event/admin/components/EventBreadcrumbs"
import { useListEventsQuery } from "event/api/event.api"

type SortedKeys = keyof Pick<Event, "kickoff_date" | "organization">

type TrProps = {
  event: Event
  onClick: () => void
}
const Tr = ({ event, onClick }: TrProps) => {
  const { id, kickoff_date, organization } = event
  return (
    <tr key={id} onClick={onClick} className="table-row cursor-pointer">
      <td>
        <Text variant="link">
          {`PSG ${event.opponent.name}` || `Événement ${event.id}`}
        </Text>
      </td>
      <AccessRights
        authorizedRoles={EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES}
        authorizedOrganizations={
          EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
        }
      >
        <td>{organization}</td>
      </AccessRights>
      <td>
        <Group>
          <Clock />{" "}
          <span>
            <strong>
              {new Date(kickoff_date)?.toLocaleString(undefined, {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </strong>
          </span>
        </Group>
      </td>
    </tr>
  )
}

function sortData(
  data: Event[],
  payload: {
    sortBy: SortedKeys
    reversed: boolean
  }
): Event[] {
  let { sortBy } = payload

  return [...data].sort((a, b) => {
    if (payload.reversed) {
      return b[sortBy].localeCompare(a[sortBy])
    }
    return a[sortBy].localeCompare(b[sortBy])
  })
}

export function EventsListView() {
  const navigate = useNavigate()
  const listEventsQuery = useListEventsQuery()
  const [sortedData, setSortedData] = useState<Event[]>([])
  const [sortBy, setSortBy] = useState<SortedKeys>("kickoff_date")
  const [reverseSortDirection, setReverseSortDirection] = useState(false)

  const [organization] = useOrganization()
  const events = useMemo(() => listEventsQuery.data, [listEventsQuery.data])

  const triggerSortingData = useCallback(
    (field: SortedKeys) => {
      if (events) {
        const reversed = field === sortBy ? !reverseSortDirection : false
        return sortData(events, { sortBy, reversed })
      }
      return []
    },
    [events, reverseSortDirection, sortBy]
  )

  useEffect(() => {
    if (events) {
      setSortedData(triggerSortingData(sortBy))
    }
  }, [events, sortBy, triggerSortingData])

  return (
    <Box>
      <EventBreadcrumbs />

      <Title order={3} align={"center"} m={"lg"}>
        Liste des événements
      </Title>

      <AccessRights
        authorizedRoles={EVENT_CREATE_AUTHORISED_ROLES}
        authorizedOrganizations={EVENT_CREATE_AUTHORISED_ORGANIZATIONS}
      >
        <Group position={"right"}>
          <Button className="bg-button-admin" component={Link} to="new">
            Nouvel événement
          </Button>
        </Group>
      </AccessRights>
      <Collapsable title={"Liste des événements"}>
        <QueryWrapper query={listEventsQuery}>
          {(events) =>
            events.length ? (
              <Table highlightOnHover>
                <thead>
                  <tr>
                    <th></th>
                    <AccessRights
                      authorizedRoles={
                        EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ROLES
                      }
                      authorizedOrganizations={
                        EVENT_ORGANIZATION_VISIBILITY_AUTHORISED_ORGANIZATIONS
                      }
                    >
                      <Th
                        reversed={reverseSortDirection}
                        sorted={sortBy === "organization"}
                        onClick={() => {
                          if (sortBy === "organization") {
                            setReverseSortDirection(!reverseSortDirection)
                          } else {
                            setSortBy("organization")
                          }
                        }}
                      >
                        Organisme
                      </Th>
                    </AccessRights>
                    <Th
                      reversed={reverseSortDirection}
                      sorted={sortBy === "kickoff_date"}
                      onClick={() => {
                        if (sortBy === "kickoff_date") {
                          setReverseSortDirection(!reverseSortDirection)
                        } else {
                          setSortBy("kickoff_date")
                        }
                      }}
                    >
                      Date
                    </Th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.length > 0 &&
                    sortedData.map((event) =>
                      (event.organization &&
                        event.organization === organization) ||
                      organization === ADMIN_ORGANIZATION ? (
                        <Tr
                          key={uuidv4()}
                          event={event}
                          onClick={() =>
                            navigate(event.id, { state: { event } })
                          }
                        />
                      ) : null
                    )}
                </tbody>
              </Table>
            ) : (
              <Stack align={"center"}>
                <Box>Aucun événement trouvé</Box>
                <Button className="bg-button-admin" component={Link} to="new">
                  Nouvel événement
                </Button>
              </Stack>
            )
          }
        </QueryWrapper>
      </Collapsable>
    </Box>
  )
}
