import { Outlet, useParams } from "react-router-dom"
import { useGetWallQuery } from "../../api/walls.endpoints"
import QueryWrapper from "shared/components/QueryWrapper"
import { PlayLayout } from "common/layouts/PlayLayout"

export function WallPlayThemeWrapper() {
  const { wallId } = useParams<{ wallId: string }>()
  const getWallQuery = useGetWallQuery(wallId!)

  return (
    <QueryWrapper query={getWallQuery}>
      {(wall) => (
        <PlayLayout theme={wall}>
          <Outlet />
        </PlayLayout>
      )}
    </QueryWrapper>
  )
}
